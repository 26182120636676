import styled from "@emotion/styled";
import { theme } from "theme";

export const Placeholder = styled.div`
  color: ${theme.color.gray[500]};
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 1in;
  left: 1in;
  font-size: ${theme.font.size.body};
  user-select: none;
  display: inline-block;
  pointer-events: none;
`;
