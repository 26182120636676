import styled from "@emotion/styled";
import { theme } from "theme";
import ImportFile from "assets/svg/import-file.svg?react";

export const Wrapper = styled.div<{ image?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${theme.spacing.xs} 0;
  width: 600px;
  min-height: unset;
  padding: ${(props) =>
    props.image ? "0" : `${theme.spacing.xxxl} ${theme.spacing.sm}`};
  border-width: 1px;
  border-style: ${(props) => (props.image ? "solid" : "dashed")};
  border-color: ${theme.color.indigo[700]};
  border-radius: ${theme.borderRadius};
  background-color: ${theme.color.gray[100]};
  max-width: 100%;
  overflow: hidden;
  transition: background-color 0.25s;
  cursor: pointer;
  &:hover {
    background-color: ${theme.color.gray[200]};
  }
`;

export const ImageWrapper = styled.div`
  line-height: 0;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing.lg};
  font-weight: 600;
  color: ${theme.color.gray[900]};
`;

export const ImportIcon = styled(ImportFile)`
  fill: currentColor;
`;
