import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  baseFilezone: {
    borderRadius: "0.75rem",
    borderOpacity: "1",
    borderColor: "#f7fafc",
    bgOpacity: "1",
    backgroundColor: "#f7fafc",
    margin: "1rem 0.5rem",
    padding: "0.75rem 0",
  },
});
