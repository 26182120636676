import styled from "@emotion/styled";
import { CTAButton } from "components";
import { theme } from "theme";

export const SwitcherWrapper = styled.div`
  width: 100%;
`;

export const DeleteButton = styled(CTAButton)`
  margin-right: auto;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.sm};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.lg};
`;
