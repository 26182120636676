import { Paper } from "@mui/material";
import {
  $getNodeByKey,
  $getSelection,
  $setSelection,
  COMMAND_PRIORITY_LOW,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import React, { useCallback, useEffect, useRef } from "react";
import { getDOMRangeRect } from "../utils/getDOMRangeRect";
import { setFloatingElemPosition } from "../utils/setFloatingElemPosition";
import { mergeRegister } from "@lexical/utils";
import { theme } from "theme";
import { CTAButton } from "components";
import { $isPlaceholderNode } from "../nodes/PlaceholderTextNode";
import { useForm, useWatch } from "react-hook-form";
import { FILL_PLACEHOLDER_COMMAND } from "../plugins/TemplatePlugin";
import { ControlledTextField } from "new-components";

type FloatingPlaceholderPopupProps = {
  editor: LexicalEditor;
  nodeKey: string;
  anchorEl: HTMLElement;
  getLabelBySlug: (slug: string) => string | null;
};

type PlaceholderForm = { slug: string; fieldKey: string; value: string };
export const FloatingPlaceholderPopup = ({
  editor,
  nodeKey,
  anchorEl,
  getLabelBySlug,
}: FloatingPlaceholderPopupProps) => {
  const popupRef = useRef<HTMLDivElement | null>(null);

  const { control, reset, handleSubmit } = useForm<PlaceholderForm>();

  const slug = useWatch({
    control,
    name: "slug",
  });

  function mouseMoveListener(e: MouseEvent) {
    if (popupRef?.current && (e.buttons === 1 || e.buttons === 3)) {
      if (popupRef.current.style.pointerEvents !== "none") {
        const x = e.clientX;
        const y = e.clientY;
        const elementUnderMouse = document.elementFromPoint(x, y);

        if (!popupRef.current.contains(elementUnderMouse)) {
          // Mouse is not over the target element => not a normal click, but probably a drag
          popupRef.current.style.pointerEvents = "none";
        }
      }
    }
  }
  function mouseUpListener(e: MouseEvent) {
    if (popupRef?.current) {
      if (popupRef.current.style.pointerEvents !== "auto") {
        popupRef.current.style.pointerEvents = "auto";
      }
    }
  }

  useEffect(() => {
    if (popupRef?.current) {
      document.addEventListener("mousemove", mouseMoveListener);
      document.addEventListener("mouseup", mouseUpListener);

      return () => {
        document.removeEventListener("mousemove", mouseMoveListener);
        document.removeEventListener("mouseup", mouseUpListener);
      };
    }
  }, [popupRef]);

  const $updateTextFormatFloatingToolbar = useCallback(() => {
    const selection = $getSelection();

    const popupCharStylesEditorElem = popupRef.current;
    const nativeSelection = window.getSelection();

    if (popupCharStylesEditorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      nativeSelection !== null &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const rangeRect = getDOMRangeRect(nativeSelection, rootElement);
      setFloatingElemPosition(rangeRect, popupCharStylesEditorElem, anchorEl);
    }
  }, [editor, anchorEl]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      const node = $getNodeByKey(nodeKey);
      if ($isPlaceholderNode(node)) {
        reset({
          slug: node.getSlug(),
          fieldKey: node.getFieldKey(),
          value: "",
        });
      }
    });
  }, [nodeKey]);

  useEffect(() => {
    const scrollerElem = anchorEl.parentElement;

    const update = () => {
      editor.getEditorState().read(() => {
        $updateTextFormatFloatingToolbar();
      });
    };

    window.addEventListener("resize", update);
    if (scrollerElem) {
      scrollerElem.addEventListener("scroll", update);
    }

    return () => {
      window.removeEventListener("resize", update);
      if (scrollerElem) {
        scrollerElem.removeEventListener("scroll", update);
      }
    };
  }, [editor, $updateTextFormatFloatingToolbar, anchorEl]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      $updateTextFormatFloatingToolbar();
    });
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateTextFormatFloatingToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          $updateTextFormatFloatingToolbar();
          return false;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor, $updateTextFormatFloatingToolbar]);

  const onSubmit = (values: PlaceholderForm) => {
    editor.dispatchCommand(FILL_PLACEHOLDER_COMMAND, {
      slug: values.slug,
      value: values.value,
      fieldKey: values.fieldKey,
      origin: "internal",
    });
    reset({ ...values, value: "" });
    editor.update(() => {
      $setSelection(null);
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: theme.spacing.sm,
          position: "absolute",
          left: 0,
          top: 0,
          zIndex: 10,
          width: 320,
          willChange: "transform",
          marginLeft: 1,
          borderRadius: theme.borderRadius,
          boxShadow: theme.shadow.alternative,
          padding: theme.spacing.md,
        }}
        ref={popupRef}
      >
        Field Name: {getLabelBySlug(slug)}
        <ControlledTextField
          control={control}
          name="value"
          size="sm"
          fullWidth
          placeholder="Override Placeholder"
        />
        <CTAButton variant="tertiary" name="Save" type="submit" />
      </Paper>
    </form>
  );
};

/*
      <StyledTextField
        variant="outlined"
        label="Value"
        size="small"
        value={value}
        InputProps={{
          endAdornment: <CTAButton variant="tertiary" name="Save" />,
        }}
      />
*/
