import { Tooltip } from "@mui/material";
import React from "react";
import { Button } from "./styles";

export type EditorToolbarButtonProps = {
  icon?: JSX.Element | undefined | null;
  label: string;
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
};

export default function EditorToolbarButton({
  icon,
  onClick,
  label,
  disabled,
  active,
}: EditorToolbarButtonProps) {
  return (
    <Tooltip title={label}>
      <Button
        type="button"
        aria-label={`text-editor-button-${label}`}
        onClick={() => {
          onClick?.();
        }}
        disabled={disabled}
        active={active}
      >
        {icon}
      </Button>
    </Tooltip>
  );
}
