import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const ContractDeleteGridViewModalContent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box className="flex-align-center">
        <Box sx={{ marginLeft: "1rem" }}>
          {t("pages.contracts.header.modals.deleteViewModal.subtitle")}
        </Box>
      </Box>
    </>
  );
};

export default ContractDeleteGridViewModalContent;
