/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ContractViewInputDto = {
  type: ContractViewInputDto.type;
  name: string;
  data: string;
  filter: string | null;
  shared: boolean;
};

export namespace ContractViewInputDto {
  export enum type {
    CONTRACT = "CONTRACT",
    CONTACT = "CONTACT",
    TASK = "TASK",
  }
}
