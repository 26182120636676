import Grid from "@mui/material/Grid";
import {
  ContractDTOV1,
  NumberFieldTypeDtoV1,
  NumberFieldConfiguration,
  ContractFieldDTOV1,
} from "openapi";
import React, { FC } from "react";
import { DataPointProps } from "./types";
import { FormNumericField } from "components/FormItems/FormNumeric/FormNumericField";
import { useLocale } from "hooks/GlobalStateHooks";
import { useFormContext } from "react-hook-form";
import { useAnalysis } from "./hooks/useAnalysis";
import { AnalysisWrapper } from "./components/AnalysisWrapper";
import { scaleLocaleDecimalNumber } from "utils/helpers";
import useDataPointAnalysis from "./hooks/useDatapointAnalysis";
import { DatapointField } from "./components/DatapointField";
import { DatapointFieldInputAdornment } from "./components/DatapointFieldInputAdornment";

export const NumberDatapoint: FC<DataPointProps> = ({
  definition,
  values,
  editable,
  showAnalysis,
  contractId,
  teamId,
  fetchData,
}) => {
  const { locale } = useLocale();
  const { control, getValues, setValue } = useFormContext<ContractDTOV1>();

  const name = definition.name[locale];
  const data = values[definition.id] as NumberFieldTypeDtoV1;
  const analysis = useAnalysis(definition, data);
  const configuration = definition.configuration as NumberFieldConfiguration;
  const decimalScale = configuration?.decimalScale || 2;

  const { suggestions, verify, selectInsight } = useDataPointAnalysis(
    definition,
    {
      id: contractId,
      teamId: teamId,
    },
    data
  );

  if (editable) {
    return (
      <Grid item xs={12} md={6}>
        <AnalysisWrapper
          analysis={showAnalysis ? analysis : undefined}
          fieldKey="value"
          definitionType={ContractFieldDTOV1.type.NUMBER}
          renderField={(data, selector) => {
            return (
              <FormNumericField
                control={control}
                name={`fields.${definition.id}.value`}
                label={definition.name[locale]}
                decimalScale={decimalScale}
                fixedDecimalScale={false}
                InputProps={{
                  startAdornment: selector ?? (
                    <DatapointFieldInputAdornment
                      definition={definition}
                      fieldKey="value"
                      suggestions={suggestions}
                      setValue={setValue}
                      data={getValues().fields[definition.id]}
                    />
                  ),
                }}
                inputProps={{
                  suggestion: !!data?.selectedSuggestion.value,
                }}
              />
            );
          }}
        />
      </Grid>
    );
  }

  return (
    <Grid item xs={12} md={6}>
      <DatapointField
        definition={definition}
        data={data}
        field="value"
        suggestions={suggestions}
        onVerify={async () => {
          await verify(["value"]);
          void fetchData?.();
        }}
        onSelectInsight={async (value) => {
          await selectInsight("value", value);
          void fetchData?.();
        }}
        name={name}
      >
        {data && data.value
          ? scaleLocaleDecimalNumber(data.value, decimalScale, locale)
          : "-"}
      </DatapointField>
    </Grid>
  );
};
