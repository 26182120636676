import React, { useState, useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { ForbiddenResponse } from "shared/types/ErrorResponse.type";
import {
  ApiError,
  OrganizationService,
  ContractInputDTOV1,
  ImportDataDTO,
  CategoryDTO,
} from "openapi";
import { useTeam } from "contexts/team/hooks";
import { useLocale, useUserInfo } from "hooks";
import { Features } from "constants/features";
import NewModal from "components/Modal/NewModal";
import ImportViaEmail from "components/ImportViaEmail/ImportViaEmail";
import { useSnackbar } from "notistack";
import globalConfig from "shared/config/global.config";
import {
  useFieldsQuery,
  useContactTypesQuery,
  useOrganizationCategoriesQuery,
  useContactDataPointsDefinitionsQuery,
} from "shared/api";
import { theme } from "theme";
import routePaths from "../../../constants/routePaths";
import { ContractUploadModal, ContractTemplatesModal } from "../Modals";
import FlatFileImporter from "components/FlatFileImporter/client";
import { IField } from "@flatfile/adapter/build/main/interfaces";
import { fillImportFields } from "components/FlatFileImporter/client/importUtils";
import { getCurrentTeams } from "components/FlatFileImporter/client/helpers";
import ImportStatusModal from "components/FlatFileImporter/client/components/ImportStatusModal/ImportStatusModal";
import { getDefaultCategory } from "shared/api/organization/categories.helpers";
import { dateFormats } from "constants/utils";
import AddIcon from "assets/svg/plus.svg?react";
import FileIcon from "assets/svg/file.svg?react";
import ContractIcon from "assets/svg/contract-icon.svg?react";
import EmailIcon from "assets/svg/mail.svg?react";
import TableIcon from "assets/svg/table.svg?react";
import { IconWrapper } from "new-components";
import ContractTemplateIcon from "assets/svg/contract-template-name-icon.svg?react";
import { DropdownButton, ArrowIcon, Menu, MenuItem } from "./styles";

export const LICENSE_KEY =
  globalConfig.REACT_APP_FLATFILE_LICENSE_KEY as string;

type Options =
  | "createDraft"
  | "importViaUpload"
  | "importCSV"
  | "importViaEmail"
  | "importViaContractTemplate";
type Props = {
  visibleOptions?: Partial<Record<Options, boolean>>;
  showLabel?: boolean;
  showTooltip: boolean;
  isSidebar?: boolean;
  canCreateContract?: boolean;
  dataCy?: string;
};
export interface Option {
  value: Options;
  label: string;
  icon: React.ReactNode;
}

const DropdownSelect = ({
  visibleOptions = {
    createDraft: true,
    importViaUpload: true,
    importCSV: true,
    importViaEmail: true,
  },
  showLabel,
  showTooltip,
  isSidebar,
  canCreateContract = true,
  dataCy,
}: Props) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { teams, userInfo } = useUserInfo();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const {
    selectedTeamId,
    hasFeature,
    organizationId,
    parentTeamId,
    hasWriteAccess,
  } = useTeam();

  const [showImportViaEmail, setShowImportViaEmail] = useState(false);
  const [showImportViaUpload, setShowImportViaUpload] = useState(false);
  const [showImportViaContractTemplate, setShowImportViaContractTemplate] =
    useState(false);
  const { data: fields } = useFieldsQuery(organizationId);
  const { enqueueSnackbar } = useSnackbar();
  const [newContractId, setNewContractId] = useState<string>();
  const buttonRef = useRef<HTMLDivElement>(null);
  const userDateFormat = userInfo?.dateFormat || dateFormats.EU_DOTS;

  const { data: fetchedCategories } =
    useOrganizationCategoriesQuery(organizationId);

  const categories = useMemo(() => {
    return fetchedCategories?.filter((category) =>
      hasWriteAccess(category.id, selectedTeamId)
    );
  }, [fetchedCategories]);

  const { data: contactFields } =
    useContactDataPointsDefinitionsQuery(parentTeamId);
  const { data: contactTypes } = useContactTypesQuery(parentTeamId);
  const [sheetsFields, setSheetsFields] = useState<IField[]>();
  const [config, setConfig] = useState({});
  const [showCSVImportStatus, setShowCSVImportStatus] = useState(false);
  const [importStatus, setImportStatus] = useState<ImportDataDTO>();

  const getPath = (route: string, id?: string): string => {
    return id && route.indexOf(":id") !== -1 ? route.replace(":id", id) : route;
  };
  // const [showImportViaIntegration, setShowImportViaIntegration] = useState(false);

  const createEmptyContract = async () => {
    if (!selectedTeamId) return;

    try {
      const isTeamMatched = (item: CategoryDTO, selectedTeamId: string) => {
        return item.teams.includes(selectedTeamId);
      };

      if (!categories) return;

      const category =
        getDefaultCategory(categories, selectedTeamId) ||
        categories.find((item) => isTeamMatched(item, selectedTeamId));

      const contract = await OrganizationService.createContract(
        selectedTeamId,
        {
          name: t("pages.contractDetails.general.newContractName"),
          status: ContractInputDTOV1.status.DRAFT,
          categoryId: category?.id,
        }
      );
      setNewContractId(contract.id);
      enqueueSnackbar(
        t("pages.contractEdit.forms.information.contractCreated"),
        {
          variant: "success",
        }
      );
    } catch (error) {
      if (!(error instanceof ApiError)) return;
      if (
        error.status === 403 &&
        (error.body as ForbiddenResponse).message === "contract limit reached"
      ) {
        enqueueSnackbar(t("contractUpload.validation.maxContractLimit"), {
          variant: "error",
        });
      }
    }

    return newContractId;
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options: Record<Options, Option> = {
    createDraft: {
      value: "createDraft",
      label: t("contractUpload.dropdown.options.withoutPdf"),
      icon: (
        <IconWrapper>
          <AddIcon />
        </IconWrapper>
      ),
    },
    importViaUpload: {
      value: "importViaUpload",
      label: t("contractUpload.dropdown.options.byUpload"),
      icon: (
        <IconWrapper>
          <ContractIcon />
        </IconWrapper>
      ),
    },
    importViaContractTemplate: {
      value: "importViaContractTemplate",
      label: t("contractUpload.dropdown.options.viaTemplate"),
      icon: (
        <IconWrapper>
          <ContractTemplateIcon />
        </IconWrapper>
      ),
    },
    importCSV: {
      value: "importCSV",
      label: t("contractUpload.dropdown.options.viaCsv"),
      icon: (
        <IconWrapper>
          <TableIcon />
        </IconWrapper>
      ),
    },
    importViaEmail: {
      value: "importViaEmail",
      label: t("contractUpload.dropdown.options.viaEmail"),
      icon: (
        <IconWrapper>
          <EmailIcon />
        </IconWrapper>
      ),
    },
    // importViaIntegration: {
    //   value: "importViaIntegration",
    //   label: "Import via integration",
    // },
  };

  const onOptionClick = (option: Option) => {
    switch (option.value) {
      case options.createDraft.value:
        return void createEmptyContract();
      case options.importViaEmail.value:
        return setShowImportViaEmail(true);
      case options.importViaUpload.value:
        return setShowImportViaUpload(true);
      case options.importViaContractTemplate.value:
        return setShowImportViaContractTemplate(true);
      default:
        return true;
    }
  };

  const filteredOptions = Object.values(options).filter((option) => {
    if (!visibleOptions[option.value]) {
      return false;
    }
    switch (option.value) {
      case options.importCSV.value:
        return hasFeature(Features.IMPORT_CSV);
      case options.importViaEmail.value:
        return hasFeature(Features.IMPORT_VIA_EMAIL);
      case options.importViaContractTemplate.value:
        return hasFeature(Features.CONTRACT_TEMPLATES);
      // case options.importViaIntegration.value:
      // return hasFeature(Features.IMPORT_VIA_INTEGRATION);
      default:
        return true;
    }
  });

  const handleOnData = (status: ImportDataDTO) => {
    setImportStatus(status);
    setShowCSVImportStatus(true);
  };

  useEffect(() => {
    if (newContractId) {
      navigate(getPath(routePaths.CONTRACT_EDITVIEW, newContractId), {
        state: { new: true },
      });
    }
  }, [newContractId]);

  useEffect(() => {
    if (categories && fields && teams) {
      void fillImportFields(
        selectedTeamId,
        parentTeamId,
        categories,
        fields,
        getCurrentTeams(teams, selectedTeamId),
        setSheetsFields,
        locale,
        t,
        userDateFormat,
        contactFields
      );
    }
  }, [categories, fields, teams, contactFields, contactTypes]);

  useEffect(() => {
    setConfig({
      categories,
      fields,
      selectedTeamId,
      migratedOrganizationId: organizationId,
      contactFields,
      contactTypes,
    });
  }, [categories, fields, contactFields, contactTypes, selectedTeamId]);

  return (
    <>
      <Tooltip
        title={showTooltip ? t("contractUpload.dropdown.button") : ""}
        placement="right"
        arrow
      >
        <DropdownButton
          ref={buttonRef}
          data-cy={dataCy}
          isOpen={!!anchorEl}
          onClick={handleClick}
          disabled={!canCreateContract}
          isSidebar={isSidebar}
        >
          <ArrowIcon isOpen={!!anchorEl} fontSize="small" />
          {showLabel && t("contractUpload.dropdown.button")}
        </DropdownButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: isSidebar ? "top" : "bottom",
          horizontal: isSidebar ? "right" : "left",
        }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        PaperProps={{
          sx: {
            borderRadius: theme.borderRadius,
            marginLeft: 1,
            overflow: "visible",
            boxShadow: theme.shadow.standard,
          },
        }}
      >
        {filteredOptions.map((option) =>
          option.value === options.importCSV.value ? (
            config &&
            sheetsFields && (
              <FlatFileImporter
                id={option.value}
                key={option.value}
                config={config}
                fields={sheetsFields}
                onResult={handleOnData}
                handleClose={handleClose}
                renderComponent={({ onClick }) => (
                  <MenuItem onClick={onClick}>
                    {option.icon}
                    {option.label}
                  </MenuItem>
                )}
              />
            )
          ) : (
            <MenuItem
              data-cy={option.value}
              id={option.value}
              key={option.value}
              onClick={() => {
                onOptionClick(option);
                handleClose();
              }}
            >
              {option.icon}
              {option.label}
            </MenuItem>
          )
        )}
      </Menu>

      <NewModal
        open={showImportViaEmail}
        handleClose={() => setShowImportViaEmail(false)}
        icon={
          <IconWrapper size={24}>
            <EmailIcon />
          </IconWrapper>
        }
        title={t("importViaEmail.title")}
        body={<ImportViaEmail />}
      />

      <NewModal
        open={showCSVImportStatus}
        handleClose={() => setShowCSVImportStatus(false)}
        title={t("pages.import.importModal.importTitle")}
        icon={
          <IconWrapper>
            <FileIcon />
          </IconWrapper>
        }
        body={
          importStatus ? <ImportStatusModal importData={importStatus} /> : <></>
        }
        fullWidth
      />

      <ContractUploadModal
        open={showImportViaUpload}
        handleClose={() => setShowImportViaUpload(false)}
      />

      <ContractTemplatesModal
        open={showImportViaContractTemplate}
        handleClose={() => setShowImportViaContractTemplate(false)}
      />

      {/* @TODO: Add when integrations are deployed to production */}
      {/* <NewModal
        open={showImportViaIntegration}
        handleClose={() => setShowImportViaIntegration(false)}
        title={t("import via integration")}
        body={<div>import integration</div>}
      /> */}
    </>
  );
};

export default DropdownSelect;
