import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  position: relative;
  border-radius: ${theme.borderRadius};
  border: 1px solid ${theme.color.gray[200]};
  opacity: ${({ disabled }) => disabled && "0.5"};
  pointer-events: ${({ disabled }) => disabled && "none"};
`;

export const ButtonWrapper = styled.div<{
  active: boolean;
  isFirst: boolean;
  isLast: boolean;
  noDivider: boolean;
}>`
  flex: 1;
  margin: ${theme.spacing.xxs} 0;
  padding: 0 ${theme.spacing.xxs};

  ${({ noDivider }) =>
    !noDivider &&
    `
    border-left: 1px solid ${theme.color.gray[300]};
    border-right: 1px solid ${theme.color.gray[300]};
  `}

  ${({ isFirst, isLast, noDivider }) =>
    (isFirst || isLast) &&
    !noDivider &&
    `
      padding: 0 ${theme.spacing.xxs};
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
    `}
`;

export const Button = styled.span<{ active: boolean }>`
  display: flex;
  justify-content: center;
  font-weight: ${theme.font.weight.semiBold};
  text-align: center;
  font-size: ${theme.font.size.body};
  line-height: 1.5rem;
  letter-spacing: 0.02rem;
  padding: ${theme.spacing.xs} ${theme.spacing.md};
  border-radius: calc(${theme.borderRadius} - 2px);
  cursor: pointer;
  position: relative;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  transition: background-color 0.15s linear;

  ${({ active }) =>
    active &&
    `
    color: var(--color-white);
    background-color: var(--color-primary);

    :hover {
      background-color: var(--color-primary-hover);
    }
  `}
`;

export const Slider = styled.div``;
