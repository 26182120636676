import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SwitchBar } from "new-components";
import { theme } from "theme";
import { routePaths } from "constants/";

export enum ViewModeType {
  VIEW = "view",
  EDIT = "edit",
}

const ContractViewSwitcher = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: contractId, mode } = useParams();

  const initialMode =
    mode === ViewModeType.EDIT ? ViewModeType.EDIT : ViewModeType.VIEW;
  const [currentMode, setCurrentMode] = useState(initialMode);
  /**
   * @TODO:
   * Need to update the container view as well
   * so we always display document container when toggling between view and edit mode.
   * this logic will be moved to the contract context provider
   *
   * Example: setSelectedTab({ id: 1, key: "documents" });
   * */

  const handleOnChangeViewMode = (value: string) => {
    if (value !== ViewModeType.EDIT) {
      setCurrentMode(ViewModeType.VIEW);
      const contractViewPath = `${routePaths.CONTRACTS}/${contractId ?? ""}`;
      navigate(contractViewPath, { replace: true });
      return;
    }

    const contractEditPath = `${routePaths.CONTRACTS}/${contractId ?? ""}/${
      ViewModeType.EDIT
    }`;
    navigate(contractEditPath, { replace: true });
    setCurrentMode(ViewModeType.EDIT);
  };

  return (
    <div style={{ margin: `${theme.spacing.xs} ${theme.spacing.xs} 0 0` }}>
      <SwitchBar
        activeKey={currentMode}
        setActiveKey={handleOnChangeViewMode}
        options={[
          {
            key: ViewModeType.EDIT,
            label: t("pages.contractDetails.buttons.editing"),
            active: currentMode === ViewModeType.EDIT,
          },
          {
            key: ViewModeType.VIEW,
            label: t("pages.contractDetails.buttons.viewing"),
            active: currentMode === ViewModeType.VIEW,
          },
        ]}
      />
    </div>
  );
};

export default ContractViewSwitcher;
