import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import type { Block } from "aws-sdk/clients/textract";
import {
  ContractDTOV1,
  ContractFileDTO,
  ContractNameDto,
  OrganizationService,
} from "openapi";
import { useTeam } from "contexts/team/hooks";
import { convertContractName } from "constants/utils";
import { Features } from "constants/features";
import { FileUploadComponent, NewModal } from "components";
import HorizontalStepper from "components/HorizontalStepper/HorizontalStepper";
import { ContractTemplateMode } from "pages/Settings/OrganizationSettings/ContractTemplates/types";
import { ContractWrapper } from "./styles";
import { PDFViewer } from "components/PDFViewer/PDFViewer";
import TemplateCollection from "pages/Settings/OrganizationSettings/ContractTemplates/TemplateCollection/TemplateCollection";
import { useTranslation } from "react-i18next";

export enum ContractPDFPageTypeEnum {
  edit,
  preview,
}

type Props = {
  contractFile?: Blob | undefined | null;
  contractGroup?: ContractNameDto[];
  contractData?: ContractDTOV1;
  refetchData: () => Promise<void>;
};

const ContractPDF = ({
  contractFile,
  contractGroup,
  contractData,
  refetchData,
}: Props) => {
  const { t } = useTranslation();
  const { hasWriteAccess, hasFeature } = useTeam();
  const [fileURL, setFileURL] = useState("");
  const [fileData, setFileData] = useState<ContractFileDTO>();
  const [isLoading, setIsLoading] = useState(false);
  const [showTemplateCollection, setShowTemplateCollection] = useState(false);

  const showSelectTemplateButton =
    hasWriteAccess(contractData?.categoryId, contractData?.teamId) &&
    hasFeature(Features.CONTRACT_TEMPLATES) &&
    contractData?.status === ContractDTOV1.status.DRAFT;

  useEffect(() => {
    const file = new Blob([contractFile as Blob], { type: contractFile?.type });
    setFileURL(`${URL.createObjectURL(file)}#view=FitH`);

    return () => {
      URL.revokeObjectURL(fileURL);
      setFileURL("");
    };
  }, [contractFile]);

  useEffect(() => {
    if (contractData?.hasFile) {
      void OrganizationService.getContractFile(
        contractData.teamId,
        contractData.id
      ).then((file) => {
        if (file) setFileData(file);
      });
    }
  }, [contractData]);

  if (isLoading) return <CircularProgress sx={{ margin: "auto" }} />;

  let fileName = fileData?.fileName;

  if (!fileName && contractData) {
    fileName = convertContractName(contractData.name);
  }

  const stepper = () =>
    contractGroup &&
    contractGroup.length > 1 && (
      <HorizontalStepper contractGroup={contractGroup} />
    );

  if (!contractFile || !fileURL) {
    return (
      <>
        <ContractWrapper>
          {stepper()}

          <FileUploadComponent
            setIsLoading={setIsLoading}
            contractId={contractData?.id as string}
            refetchPdf={refetchData}
            showSelectTemplateButton={showSelectTemplateButton}
            showImportFromTemplateModal={() => setShowTemplateCollection(true)}
          />
        </ContractWrapper>

        {hasFeature(Features.CONTRACT_TEMPLATES) && (
          <NewModal
            fullWidth
            maxWidth="xl"
            open={showTemplateCollection}
            handleClose={() => setShowTemplateCollection(false)}
            title={t("pages.settings.tabs.contractTemplates.title")}
            body={
              <TemplateCollection
                mode={ContractTemplateMode.TEMPORARY_SELECT}
                contractId={contractData?.id as string}
                contract={contractData}
                handleClose={() => {
                  setShowTemplateCollection(false);
                  void refetchData();
                }}
              />
            }
          />
        )}
      </>
    );
  }

  return (
    <ContractWrapper>
      {stepper()}
      <PDFViewer
        fileURL={fileURL}
        fileName={fileName}
        textractBlocks={fileData?.blocks as Block[]}
      />
    </ContractWrapper>
  );
};

export default ContractPDF;
