import styled from "@emotion/styled";
import CustomDuplicateIcon from "assets/svg/duplicate-icon.svg?react";
import CustomTemplateNameIcon from "assets/svg/contract-template-name-icon.svg?react";
import CustomCalendarIcon from "assets/svg/calendar-icon.svg?react";
import CustomRenameTemplateIcon from "assets/svg/alphabet.svg?react";
import CustomBinIcon from "assets/svg/bin-icon.svg?react";
import { theme } from "theme";

export const NoContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: ${theme.color.gray[600]};

  span {
    padding: ${theme.spacing.sm};
    background-color: ${theme.color.gray[300]};
    color: ${theme.color.gray[600]};
    font-size: ${theme.font.size.caption};
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 0;
`;

export const ImagePreview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 2.4px;
  box-shadow: ${theme.shadow.standard};
  width: 170px;
  height: 240px;
  cursor: pointer;
  position: relative;
  padding: 0 ${theme.spacing.sm};
  background-color: ${theme.color.white};
  transition: border 0.15s ease-in-out;

  :hover {
    border: 1px solid ${theme.color.blue[600]};
  }
`;

export const TemplateInfo = styled.div`
  margin: ${theme.spacing.md} 0;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xs};
`;

const commonIconStyles = `
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xs};
  color: ${theme.color.gray[700]};
`;

export const TemplateNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TemplateName = styled.div`
  ${commonIconStyles}
  font-weight: ${theme.font.weight.semiBold};

  span {
    max-width: 112px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const TemplateDate = styled.div`
  ${commonIconStyles}
  font-weight: ${theme.font.weight.normal};
`;

export const TemplateCategory = styled.div<{ color: string }>`
  padding: ${theme.spacing.sm};
  border-radius: ${theme.borderRadius};
  transition: background-color 0.15s ease-in-out;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};

  :hover {
    background-color: ${theme.color.gray[200]};
    cursor: pointer;
  }
`;

export const TemplateIcon = styled(CustomTemplateNameIcon)`
  fill: ${theme.color.gray[700]};
  width: 20px;
`;

export const BinIcon = styled(CustomBinIcon)`
  fill: ${theme.color.red[600]};
`;

export const TemplateRenameIcon = styled(CustomRenameTemplateIcon)`
  fill: ${theme.color.gray[700]};
  width: 20px;
`;

export const CalendarIcon = styled(CustomCalendarIcon)`
  fill: ${theme.color.gray[700]};
  width: 20px;
`;

export const DuplicateIcon = styled(CustomDuplicateIcon)`
  fill: ${theme.color.gray[700]};
  width: 20px;
`;

export const Menu = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.sm};
`;

export const MenuItem = styled.li<{ remove?: boolean }>`
  display: flex;
  gap: ${theme.spacing.sm};
  align-items: center;
  padding: ${theme.spacing.xs} ${theme.spacing.sm};
  border-radius: ${theme.borderRadius};

  ${({ remove }) =>
    remove &&
    `
    color: ${theme.color.red[600]};
  `}

  :hover {
    background-color: ${theme.color.gray[200]};
    cursor: pointer;
  }
`;
