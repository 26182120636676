import styled from "@emotion/styled";
import { breakpoints, theme } from "theme";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${theme.color.gray[300]};
  border-bottom: 1px solid ${theme.color.gray[300]};
  justify-content: flex-start;
  background-color: ${theme.color.white};
  padding: ${theme.spacing.sm};
  gap: ${theme.spacing.xs};
  position: sticky;
  top: 0;
  z-index: 2;
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.lg}) {
    justify-content: space-evenly;
  }
`;
