import { AuthService, OpenAPI } from "../../openapi";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import {
  ClearTokens,
  GetAccessToken,
  GetRefreshToken,
  SaveTokens,
} from "../service/authService";
import { LockManager } from "navigator.locks";
import globalConfig from "./global.config";
import { NavigateFunction } from "react-router-dom";
import routePaths from "constants/routePaths";
import { CustomEventTypeEnum } from "shared/enums/customevents.enum";
import * as Sentry from "@sentry/react";

OpenAPI.BASE = (globalConfig.REACT_APP_BASE_URL as string) || "/api";
OpenAPI.CREDENTIALS = "include";
OpenAPI.WITH_CREDENTIALS = true;

export const interceptorConfig = (() => {
  let _logoutAction = () => {
    ClearTokens();
  };
  let _navigateAction: NavigateFunction;
  let _language = "de";

  axios.interceptors.request.use((config) => {
    config.headers["Accept-Language"] = _language;
    const token = GetAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    // @Todo improve condition
    if (
      config.url?.includes("download") ||
      config.url?.includes("export") ||
      config.url?.includes("preview") ||
      config.url?.includes("render-template")
    ) {
      config.responseType = "blob";
    }

    return config;
  });

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err: AxiosError) => {
      const config = err.config as AxiosRequestConfig & {
        _retry: boolean;
      };

      if (err.response?.status === 401) {
        if (config.url === `${OpenAPI.BASE}/auth/login`) {
          return Promise.reject(err);
        }

        const refreshToken = GetRefreshToken();
        if (
          config._retry ||
          !refreshToken ||
          config.url === `${OpenAPI.BASE}/auth/refresh`
        ) {
          _logoutAction();
          return Promise.reject(err);
        }

        config._retry = true;

        try {
          const navigator = window.navigator as Navigator & {
            locks: LockManager;
          };
          await navigator.locks.request("refresh_auth", async () => {
            const latestRefreshToken = GetRefreshToken();
            if (latestRefreshToken === refreshToken) {
              const tokenResponseDto = await AuthService.refresh({
                refreshToken,
              });
              SaveTokens(tokenResponseDto);
            }
          });

          return axios(config);
        } catch (_error) {
          _logoutAction();
          return Promise.reject(_error);
        }
      }

      // fix for merging contacts simultaniously
      if (err.response?.status === 404) {
        const pattern = /contacts\/entities(?!\/non-duplicates)/;
        if (
          !(
            config.method === "delete" && pattern.test(err.request.responseURL)
          ) &&
          !(
            config.method === "get" &&
            /\/api\/v1\/contract-signature\/signing\/.*/.test(
              err.request.responseURL
            )
          ) &&
          // temp fix for redirect to 404 when deleting contract template
          !(
            config.method === "get" &&
            /\/contract-template\/templates\/[^/]+\/preview/.test(
              err.request.responseURL
            )
          ) &&
          !(
            config.method === "get" &&
            /\/api\/v1\/organizations\/.+\/members\/.+\/roles/.test(
              err.request.responseURL
            )
          ) &&
          !(config.method === "get" && err.request.responseURL.endsWith("/ocr"))
        ) {
          Sentry.captureException(err);
          _navigateAction(routePaths.NOTFOUND);
        }
      }

      if (err.response?.status === 500) {
        document.dispatchEvent(new CustomEvent(CustomEventTypeEnum.ERROR500));
      }

      return Promise.reject(err);
    }
  );

  return {
    setLogoutAction(logoutAction: () => void) {
      _logoutAction = logoutAction;
    },
    setLanguage(language: string) {
      _language = language;
    },
    setNavigateAction(navigate: NavigateFunction) {
      _navigateAction = navigate;
    },
  };
})();
