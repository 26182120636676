import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { EditorState, LexicalEditor, $getRoot, $isTextNode } from "lexical";
import { useTeam } from "contexts/team/hooks";
import { ContractTemplateService, ContractAttachmentService } from "openapi";
import routePaths from "constants/routePaths";

export enum viewModeType {
  VIEW = "view",
  EDIT = "edit",
}

interface ContractDetailsContextType {
  viewMode?: viewModeType | null;
  setViewMode: (mode: viewModeType) => void;
  hasUnsavedEditorChanges?: boolean;
  setHasUnsavedEditorChanges: (value: boolean) => void;
  isLoadingCreatePDFDocument: boolean;
  createPDFDocument: (contractId: string, refetch: () => void) => Promise<void>;
  updateDocument: (contractId: string, templateId?: string) => Promise<void>;
  deleteDocument: (contractId: string) => Promise<void>;
  editorRef: React.RefObject<LexicalEditor>;
  getEditorContent: (state?: EditorState) => string;
}

export const ContractDetailsContext = createContext<
  ContractDetailsContextType | undefined
>(undefined);

export const ContractDetailsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState<viewModeType>();
  const [hasUnsavedEditorChanges, setHasUnsavedEditorChanges] = useState(false);
  const { organizationId } = useTeam();
  const navigate = useNavigate();
  const [isLoadingCreatePDFDocument, setIsLoadingCreatePDFDocument] =
    useState(false);

  const editorRef = useRef<LexicalEditor>(null);

  const getEditorContent = (state?: EditorState) => {
    const editorState = state || editorRef.current?.getEditorState();
    if (editorState) {
      return editorState.read(() => {
        const root = $getRoot();
        let content = "";
        root.getChildren().forEach((node) => {
          if ($isTextNode(node)) {
            content += node.getTextContent();
          } else {
            content += node.getTextContent() + "\n";
          }
        });
        return content.trim();
      });
    }
    return "";
  };

  const updateDocument = async (contractId: string, templateId?: string) => {
    try {
      if (!contractId || !templateId) return;

      const editorState = editorRef.current?.getEditorState();

      if (!editorState) return;

      const serializedState = editorState.toJSON();

      await ContractAttachmentService.updateFileForContract(contractId, {
        file: new File(
          [
            JSON.stringify({
              templateId,
              content: serializedState,
            }),
          ],
          "contracthero/lexical"
        ),
      });

      enqueueSnackbar(
        t("pages.contractDetails.notifications.success_update_document"),
        {
          variant: "success",
        }
      );
    } catch (error) {
      enqueueSnackbar(
        t("pages.contractDetails.notifications.error_update_document"),
        {
          variant: "error",
        }
      );
    }
  };

  const createPDFDocument = async (contractId: string, refetch: () => void) => {
    try {
      setIsLoadingCreatePDFDocument(true);
      const pdf = (await ContractTemplateService.renderContractTemplate(
        organizationId,
        {
          editorState: JSON.stringify(editorRef.current?.getEditorState()),
        }
      )) as Blob;

      if (!contractId || !pdf) return;

      await ContractAttachmentService.deleteFileFromContract(contractId);
      await ContractAttachmentService.uploadFileForContract(contractId, {
        file: pdf,
      });

      setIsLoadingCreatePDFDocument(false);
      navigate(`${routePaths.CONTRACTS}/${contractId}`);
      setViewMode(viewModeType.VIEW);
      void refetch();

      enqueueSnackbar(
        t("pages.contractDetails.notifications.success_create_pdf_document"),
        {
          variant: "success",
        }
      );
    } catch (error) {
      enqueueSnackbar(
        t("pages.contractDetails.notifications.error_create_pdf_document"),
        {
          variant: "error",
        }
      );
    }
  };

  const deleteDocument = async (contractId: string) => {
    try {
      await ContractAttachmentService.deleteFileFromContract(contractId);

      enqueueSnackbar(
        t("pages.contractDetails.notifications.success_delete_document"),
        {
          variant: "success",
        }
      );
    } catch (error) {
      enqueueSnackbar(
        t("pages.contractDetails.notifications.error_delete_document"),
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <ContractDetailsContext.Provider
      value={{
        viewMode,
        setViewMode,
        hasUnsavedEditorChanges,
        setHasUnsavedEditorChanges,
        isLoadingCreatePDFDocument,
        createPDFDocument,
        updateDocument,
        deleteDocument,
        editorRef,
        getEditorContent,
      }}
    >
      {children}
    </ContractDetailsContext.Provider>
  );
};

export const useContractDetails = () => {
  const context = useContext(ContractDetailsContext);
  if (context === undefined) {
    throw new Error(
      "useContractDetails must be used within a ContractDetailsProvider"
    );
  }
  return context;
};
