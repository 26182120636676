import React, { useState, useEffect, MouseEvent } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ContractFieldDTOV1 } from "openapi";
import { IconButton, InputAdornment } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { StyledTextField } from "components/StyledComponents/StyledBaseFields";
import {
  AddNewButton,
  DraggableField,
  PopoverComponent,
} from "../../components";
import {
  Background,
  Column,
  ColumnTitle,
  ListContainer,
} from "../containerStyles";
import { AddNewFieldModal, DeleteFieldModal, EditFieldModal } from "./Modals";
import theme from "theme/theme";
import { useLocale } from "hooks/GlobalStateHooks";
import { Menu } from "./components";
import { Features } from "constants/features";
import { useTeam } from "contexts/team/hooks";
import aiFieldsIds from "./manually_marked_ai_fields.json";
import { getIdByName } from "../../utils";
import {
  Actions,
  TooltipRow,
  tooltipStyles,
  StyledAiIcon,
  StyledAiFormulaIcon,
  StyledUsedIcon,
} from "./styles";
import { InfoTooltip } from "components/InfoTooltip/InfoTooltip";

export type FieldProps = {
  id: string;
  name: string;
};

type FieldsProps = {
  fields: ContractFieldDTOV1[];
  isFieldUsed: (fieldId: string) => boolean;
  refetchData: () => Promise<void>;
};

export type SortDirection = "asc" | "desc" | null;
export type SortType = "name" | "date" | "ai" | "used" | "default" | null;

export const SORT_DIRECTIONS = {
  ASC: "asc",
  DESC: "desc",
};

export const SORT_TYPES = {
  NAME: "name",
  DATE: "date",
  AI: "ai",
  USED: "used",
  DEFAULT: "default",
};

const Fields = ({ fields, isFieldUsed, refetchData }: FieldsProps) => {
  const { locale } = useLocale();
  const { t } = useTranslation();
  const { hasFeature } = useTeam();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedField, setSelectedField] = useState<ContractFieldDTOV1>();
  const [popoverElement, setPopoverElement] =
    useState<HTMLButtonElement | null>();
  const [sortDirectionState, setSortDirectionState] =
    useState<SortDirection>(null);
  const [sortTypeState, setSortTypeState] = useState<SortType>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState<ContractFieldDTOV1[]>(fields);

  const open = Boolean(popoverElement);

  const handleOnOpenPopover = (
    event: MouseEvent<HTMLButtonElement>,
    fieldObj: ContractFieldDTOV1
  ) => {
    setPopoverElement(event.currentTarget);
    setSelectedField(structuredClone(fieldObj));
  };

  const handleOnClosePopover = () => setPopoverElement(null);

  const { register, watch, reset } = useForm();
  const watchSearchInput = (
    (watch("search") as string) ?? ""
  ).toLocaleLowerCase();
  useEffect(
    () => setSearchTerm(watchSearchInput.toLowerCase()),
    [watchSearchInput]
  );

  const sortData = (dataToSort: ContractFieldDTOV1[]) => {
    if (!sortTypeState || !sortDirectionState) return dataToSort;

    return [...dataToSort].sort((a, b) => {
      if (sortTypeState === SORT_TYPES.NAME) {
        return sortDirectionState === SORT_DIRECTIONS.ASC
          ? b.name[locale].localeCompare(a.name[locale])
          : a.name[locale].localeCompare(b.name[locale]);
      }
      if (sortTypeState === SORT_TYPES.DATE) {
        return sortDirectionState === SORT_DIRECTIONS.ASC
          ? new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          : new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      }
      if (sortTypeState === SORT_TYPES.AI) {
        const aIsAi = aiFieldsIds.includes(a.visibleId ?? "") ? 1 : 0;
        const bIsAi = aiFieldsIds.includes(b.visibleId ?? "") ? 1 : 0;
        return sortDirectionState === SORT_DIRECTIONS.ASC
          ? aIsAi - bIsAi
          : bIsAi - aIsAi;
      }
      if (sortTypeState === SORT_TYPES.USED) {
        const aIsUsed = isFieldUsed(a.id) ? 1 : 0;
        const bIsUsed = isFieldUsed(b.id) ? 1 : 0;
        return sortDirectionState === SORT_DIRECTIONS.ASC
          ? aIsUsed - bIsUsed
          : bIsUsed - aIsUsed;
      }
      return 0;
    });
  };

  const filterData = (dataToFilter: ContractFieldDTOV1[]) => {
    return dataToFilter.filter((field) =>
      field.name[locale].toLowerCase().includes(searchTerm)
    );
  };

  useEffect(() => {
    let newData = [...fields];
    newData = sortData(newData);
    newData = filterData(newData);
    setData(newData);
  }, [fields, sortData, filterData]);

  const handleSort = (type: SortType) => {
    if (type === sortTypeState) {
      setSortDirectionState(
        (prev) =>
          (prev === SORT_DIRECTIONS.DESC
            ? SORT_DIRECTIONS.ASC
            : SORT_DIRECTIONS.DESC) as SortDirection
      );
    } else {
      setSortTypeState(type);
      setSortDirectionState(SORT_DIRECTIONS.DESC as SortDirection);
    }
  };

  const resetSorting = () => {
    setSortTypeState(null);
    setSortDirectionState(null);
  };

  const handleOnClick = (field: ContractFieldDTOV1) => {
    if (isFieldUsed(field.id)) {
      const fieldInSectionElement = document.querySelector(
        `[data-id="sortable-${field.id}"]`
      );

      if (fieldInSectionElement) {
        fieldInSectionElement.scrollIntoView({ behavior: "smooth" });
        fieldInSectionElement.classList.add("highlighted-sortable");

        setTimeout(() => {
          fieldInSectionElement.classList.remove("highlighted-sortable");
        }, 2500);
      }
    }
  };

  return (
    <Column type="secondary">
      <ColumnTitle>
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <span>
            {t("pages.settings.organization.administrator.fields.title")}
          </span>
          <InfoTooltip customStyles={tooltipStyles} showArrow={true}>
            <>
              <TooltipRow>
                {t(
                  "pages.settings.organization.administrator.fields.tooltips.title"
                )}{" "}
              </TooltipRow>
              <TooltipRow>
                <StyledAiIcon />
                <em>
                  {t(
                    "pages.settings.organization.administrator.fields.tooltips.aiTag.title"
                  )}
                  <span>
                    {t(
                      "pages.settings.organization.administrator.fields.tooltips.aiTag.text"
                    )}
                  </span>
                </em>
              </TooltipRow>
              <TooltipRow>
                <div>
                  <StyledAiFormulaIcon />
                </div>
                <em>
                  {t(
                    "pages.settings.organization.administrator.fields.tooltips.aiFormulaTag.title"
                  )}
                  <span>
                    {t(
                      "pages.settings.organization.administrator.fields.tooltips.aiFormulaTag.text"
                    )}
                  </span>
                </em>
              </TooltipRow>
              <TooltipRow>
                <StyledUsedIcon />
                <em>
                  {t(
                    "pages.settings.organization.administrator.fields.tooltips.usedTag.title"
                  )}
                  <span>
                    {t(
                      "pages.settings.organization.administrator.fields.tooltips.usedTag.text"
                    )}
                  </span>
                </em>
              </TooltipRow>
            </>
          </InfoTooltip>
        </div>

        <Actions>
          <Menu
            sortDirection={sortDirectionState}
            onSort={handleSort}
            onReset={resetSorting}
          />
          <AddNewButton
            id="addNewFieldButton"
            onClick={() => setOpenCreateModal(true)}
            text={t("pages.settings.organization.administrator.fields.addNew")}
          />
        </Actions>
      </ColumnTitle>

      <Background white={true}>
        <StyledTextField
          {...register("search")}
          size="small"
          sx={{
            display: "flex",
            mx: theme.spacing.xs,
            marginBottom: theme.spacing.sm,
          }}
          placeholder={t(
            "pages.settings.organization.administrator.fields.search.placeholder"
          )}
          InputProps={{
            endAdornment: watchSearchInput ? (
              <InputAdornment position="end" sx={{ mr: theme.spacing.xs }}>
                <IconButton onClick={() => reset({ search: "" })} edge="end">
                  <ClearIcon sx={{ width: "16px", height: "16px" }} />
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
        />
        <ListContainer id="custom-fields-list">
          {fields &&
            data.map((field) => {
              const isFieldAiInsight = aiFieldsIds.includes(
                field?.visibleId ?? ""
              );
              return (
                <DraggableField
                  id={field.id}
                  isUsed={isFieldUsed(field.id)}
                  isFieldAiInsight={
                    isFieldAiInsight && hasFeature(Features.CONTRACT_ANALYSIS)
                  }
                  handleOnClick={() => handleOnClick(field)}
                  key={field.id}
                  name={field.name[locale]}
                  field={field}
                  isSettingsDisabled={field.oldStandardField}
                  handleOnOpenPopover={(e: MouseEvent<HTMLButtonElement>) =>
                    handleOnOpenPopover(e, field)
                  }
                  popoverComponent={
                    <PopoverComponent
                      id={field.id}
                      deleteOptionId={`${getIdByName(
                        field.name[locale]
                      )}-field-settings-btn-delete`}
                      editOptionId={`${getIdByName(
                        field.name[locale]
                      )}-field-settings-btn-edit`}
                      open={open}
                      popoverElement={popoverElement}
                      setOpenEditModal={() => setOpenEditModal(true)}
                      setOpenDeleteModal={() => setOpenDeleteModal(true)}
                      handleOnClosePopover={handleOnClosePopover}
                    />
                  }
                />
              );
            })}
        </ListContainer>
      </Background>

      <AddNewFieldModal
        open={openCreateModal}
        onClose={() => {
          void refetchData();
          setOpenCreateModal(false);
        }}
      />

      {selectedField && (
        <>
          <DeleteFieldModal
            open={openDeleteModal}
            field={selectedField}
            handleOnClose={() => {
              setOpenDeleteModal(false);
              handleOnClosePopover();
            }}
          />

          <EditFieldModal
            open={openEditModal}
            field={selectedField}
            onClose={() => {
              setOpenEditModal(false);
              handleOnClosePopover();
            }}
          />
        </>
      )}
    </Column>
  );
};

export default Fields;
