import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getNodeByKey,
  $getSelection,
  $insertNodes,
  $isParagraphNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $isTextNode,
  $nodesOfType,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_EDITOR,
  CONTROLLED_TEXT_INSERTION_COMMAND,
  createCommand,
  LexicalCommand,
} from "lexical";
import { useEffect } from "react";
import { mergeRegister } from "@lexical/utils";
import { TemplateComponentProps } from "../components/PlaceholderComponent";
import {
  $createPlaceholderTextNode,
  $isPlaceholderNode,
  PlaceholderTextNode,
} from "../nodes/PlaceholderTextNode";

type FillPlaceholderPayload = {
  origin: string;
  slug: string;
  fieldKey: string;
  value: string | null;
};

export const FILL_PLACEHOLDER_COMMAND: LexicalCommand<FillPlaceholderPayload> =
  createCommand("FILL_PLACEHOLDER_COMMAND");

type TemplatePluginProps = {
  getLabelBySlug?: (slug: string) => string | null;
  onFillPlaceholder?: (payload: FillPlaceholderPayload) => void;
};

export default function TemplatePlugin(props: TemplatePluginProps) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return mergeRegister(
      editor.registerNodeTransform(PlaceholderTextNode, (node) => {
        if (
          props.getLabelBySlug &&
          node.getTextContent() ===
            PlaceholderTextNode.getDefaultText(node.__slug, node.__fieldKey)
        ) {
          node.setTextContent(
            PlaceholderTextNode.getDefaultText(
              props.getLabelBySlug(node.__slug) ?? node.getTextContent(),
              node.__fieldKey // somehow localize the field key
            )
          );
        }
      }),
      editor.registerCommand(
        FILL_PLACEHOLDER_COMMAND,
        (payload) => {
          const { slug, fieldKey, value } = payload;
          const placeholders = $nodesOfType(PlaceholderTextNode);

          const targetPlaceholder = placeholders.find(
            (placeholder) =>
              placeholder.__slug === slug && placeholder.__fieldKey === fieldKey
          );
          if (targetPlaceholder) {
            targetPlaceholder.setTextContent(value ?? "");
            props.onFillPlaceholder?.(payload);
            return true;
          } else {
            return false;
          }
        },
        COMMAND_PRIORITY_EDITOR
      ),
      /*editor.registerMutationListener(PlaceholderNode, (mutatedNodes) => {
        for (const [nodeKey, mutation] of mutatedNodes) {
          if (mutation === "updated") {
            editor.getEditorState().read(() => {
              const node = $getNodeByKey(nodeKey);
              if (node instanceof PlaceholderNode) {
                props.onFillPlaceholder?.({
                  slug: node.__slug,
                  value: node.getValue(),
                  origin: node.getLastUpdateOrigin() as ValueOrigins, //clean this up
                });
              }
            });
          }
        }
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          const selection = $getSelection();
          if (!$isRangeSelection(selection)) {
            return false;
          }
          const focusNode = selection.focus.getNode();
          const anchorNode = selection.anchor.getNode();

          // CASE 1: If the selection is either the same point, or the focus node and anchor node are equal,
          // then we make sure that the entire placeholder is selected.
          console.log(selection);
          if (
            (selection.isCollapsed() || focusNode.is(anchorNode)) &&
            ((selection.anchor.offset !== 0 &&
              selection.anchor.offset < anchorNode.getTextContentSize()) ||
              (selection.focus.offset !== 0 &&
                selection.focus.offset < anchorNode.getTextContentSize())) &&
            $isPlaceholderNode(anchorNode)
          ) {
            anchorNode.select(0);
            return true;
          }

          if (
            !$isPlaceholderNode(anchorNode) &&
            $isPlaceholderNode(focusNode)
          ) {
            const newSelection = selection.clone();
            const focusBeforeAnchor = selection.focus.isBefore(
              selection.anchor
            );
            newSelection.focus.offset = focusBeforeAnchor
              ? 0
              : focusNode.getTextContentSize();
            $setSelection(newSelection);
            return true;
          }

          return false;
        },
        COMMAND_PRIORITY_EDITOR
      ),*/
      editor.registerCommand(
        CONTROLLED_TEXT_INSERTION_COMMAND,
        (e) => {
          const selection = $getSelection();

          if (typeof e === "string" || e.inputType !== "insertFromDrop") {
            return false;
          }

          const data = JSON.parse(
            e.dataTransfer?.getData("text/plain") || "{}"
          ) as TemplateComponentProps;

          if ($isRangeSelection(selection)) {
            const node = $getNodeByKey(selection.anchor.key);
            if (!node) {
              return false;
            }

            const placeholderNode = $createPlaceholderTextNode(
              data.slug,
              data.fieldKey
            );

            if ($isRootOrShadowRoot(node)) {
              $insertNodes([placeholderNode]);
              return true;
            }

            if ($isParagraphNode(node)) {
              node.append(placeholderNode);
              placeholderNode.selectEnd();
              return true;
            }

            if ($isPlaceholderNode(node)) {
              if (selection.focus.offset < node.getTextContentSize() / 2) {
                node.insertBefore(placeholderNode);
              } else {
                node.insertAfter(placeholderNode);
              }
              placeholderNode.selectEnd();
              return true;
            }

            if (!$isTextNode(node) || !node.isSimpleText()) {
              return false;
            }

            if (selection.anchor.offset === 0) {
              node.insertBefore(placeholderNode);
            } else {
              const [targetNode] = node.splitText(selection.anchor.offset);
              targetNode.insertAfter(placeholderNode);
            }
            placeholderNode.selectEnd();
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [editor]);

  return null;
}
