import styled from "@emotion/styled";
import { theme } from "theme";

export const EditorContainer = styled.div`
  display: flex;
  border-radius: 2px;
  background-color: #fcfaf6;
  color: #000;
  position: relative;
  line-height: 1.5;
  font-weight: 400;
  text-align: left;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.lg};
  padding: ${theme.spacing.lg};
  width: 330px;
  background-color: ${theme.color.white};
  border-left: 1px solid ${theme.color.gray[300]};
`;

export const TemplateComponents = styled.div`
  display: flex;
  gap: ${theme.spacing.lg};
  flex-direction: column;
  overflow: hidden;
`;

export const Components = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
  flex-wrap: wrap;
`;

export const Title = styled.div`
  position: relative;

  span {
    position: relative;
    padding-right: ${theme.spacing.sm};
    font-size: ${theme.font.size.caption};
    background: ${theme.color.white};
    color: ${theme.color.gray[600]};
    font-weight: ${theme.font.weight.semiBold};
    letter-spacing: 0.4px;
    text-transform: uppercase;
    z-index: 1;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    width: 100%;
    background-color: ${theme.color.gray[300]};
  }
`;

export const TemplateComponentWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
  align-items: center;
  border: 1px solid ${theme.color.gray[300]};
  padding: ${theme.spacing.sm};
  border-radius: 4px;
  flex-wrap: wrap;
  user-select: none;
  color: ${theme.color.gray[700]};
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  svg {
    fill: currentColor;
    max-width: 14px;
    max-height: 14px;
  }
`;
