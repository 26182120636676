/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnnotationDataDto } from "../models/AnnotationDataDto";
import type { AnnotationFieldDto } from "../models/AnnotationFieldDto";
import type { CategoryMappingCreateDto } from "../models/CategoryMappingCreateDto";
import type { CategoryMappingDto } from "../models/CategoryMappingDto";
import type { ContactDTO } from "../models/ContactDTO";
import type { ContractCategoryBlueprintCreateDto } from "../models/ContractCategoryBlueprintCreateDto";
import type { ContractCategoryBlueprintDto } from "../models/ContractCategoryBlueprintDto";
import type { PendingContractDto } from "../models/PendingContractDto";
import type { PendingContractListDto } from "../models/PendingContractListDto";
import type { PendingContractUpdateDto } from "../models/PendingContractUpdateDto";
import type { TeamDetailsDto } from "../models/TeamDetailsDto";
import type { TeamDetailsUpdateDTO } from "../models/TeamDetailsUpdateDTO";
import type { TeamListItemDto } from "../models/TeamListItemDto";
import type { UserDetailsDto } from "../models/UserDetailsDto";
import type { UserListDto } from "../models/UserListDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class AdminService {
  /**
   * Finds users for admin
   * @param search
   * @returns UserListDto
   * @throws ApiError
   */
  public static findUsers(search: string): CancelablePromise<UserListDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/admin/users",
      query: {
        search: search,
      },
    });
  }

  /**
   * Get user details by id
   * @param id
   * @returns UserDetailsDto
   * @throws ApiError
   */
  public static getUserDetails(id: string): CancelablePromise<UserDetailsDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/admin/users/{id}",
      path: {
        id: id,
      },
    });
  }

  /**
   * List pending contracts
   * @returns PendingContractListDto
   * @throws ApiError
   */
  public static getPendingContracts(): CancelablePromise<PendingContractListDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/admin/contracts",
    });
  }

  /**
   * Get pending contract
   * @param id
   * @returns PendingContractDto
   * @throws ApiError
   */
  public static getPendingContract(
    id: string
  ): CancelablePromise<PendingContractDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/admin/contracts/{id}",
      path: {
        id: id,
      },
    });
  }

  /**
   * Update contracts by Admin
   * @param id
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static updateContract(
    id: string,
    requestBody: PendingContractUpdateDto
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/admin/contracts/{id}",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Get OCR for pending contract
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static getOcrForPendingContract(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/admin/contracts/{id}/ocr",
      path: {
        id: id,
      },
    });
  }

  /**
   * Retrigger OCR run for a contract
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static triggerOcr(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/admin/contracts/{id}/ocr",
      path: {
        id: id,
      },
    });
  }

  /**
   * Get available contacts by category
   * @param id
   * @returns ContactDTO
   * @throws ApiError
   */
  public static getContactsOfCategory(
    id: string
  ): CancelablePromise<Array<ContactDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/admin/categories/{id}/contacts",
      path: {
        id: id,
      },
    });
  }

  /**
   * Set annotation data
   * @param contractId
   * @param definitionId
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static setAnnotationData(
    contractId: string,
    definitionId: string,
    requestBody: AnnotationDataDto
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/admin/contracts/{contractId}/fields/{definitionId}/annotation-data",
      path: {
        contractId: contractId,
        definitionId: definitionId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Download contract pdf
   * @param id
   * @returns any
   * @returns binary
   * @throws ApiError
   */
  public static downloadContract(id: string): CancelablePromise<any | Blob> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/admin/download/{id}",
      path: {
        id: id,
      },
    });
  }

  /**
   * Get blueprint categories
   * @returns ContractCategoryBlueprintDto
   * @throws ApiError
   */
  public static getBlueprintCategories(): CancelablePromise<
    Array<ContractCategoryBlueprintDto>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/admin/blueprint-categories",
    });
  }

  /**
   * Create blueprint category
   * @param requestBody
   * @returns ContractCategoryBlueprintDto
   * @throws ApiError
   */
  public static createBlueprintCategory(
    requestBody: ContractCategoryBlueprintCreateDto
  ): CancelablePromise<ContractCategoryBlueprintDto> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/admin/blueprint-categories",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Delete blueprint category
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static deleteBlueprintCategory(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/admin/blueprint-categories/{id}",
      path: {
        id: id,
      },
    });
  }

  /**
   * Get category mapping
   * @param search
   * @returns CategoryMappingDto
   * @throws ApiError
   */
  public static getCategoryMappings(
    search: string
  ): CancelablePromise<Array<CategoryMappingDto>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/admin/category-mappings",
      query: {
        search: search,
      },
    });
  }

  /**
   * Create category mapping
   * @param categoryId
   * @param blueprintId
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static createCategoryMapping(
    categoryId: string,
    blueprintId: string,
    requestBody: CategoryMappingCreateDto
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/admin/category-mappings/{categoryId}/blueprint/{blueprintId}",
      path: {
        categoryId: categoryId,
        blueprintId: blueprintId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Finds teams for admin
   * @param search
   * @returns TeamListItemDto
   * @throws ApiError
   */
  public static findTeams(
    search: string
  ): CancelablePromise<Array<TeamListItemDto>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/admin/teams",
      query: {
        search: search,
      },
    });
  }

  /**
   * Get team details by id
   * @param id
   * @returns TeamDetailsDto
   * @throws ApiError
   */
  public static getTeamDetails(id: string): CancelablePromise<TeamDetailsDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/admin/teams/{id}",
      path: {
        id: id,
      },
    });
  }

  /**
   * Update team chargebee id
   * @param organizationId
   * @param requestBody
   * @returns TeamDetailsDto
   * @throws ApiError
   */
  public static updateOrganization(
    organizationId: string,
    requestBody: TeamDetailsUpdateDTO
  ): CancelablePromise<TeamDetailsDto> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/admin/teams/{organizationId}",
      path: {
        organizationId: organizationId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Get all annotated fields
   * @returns AnnotationFieldDto
   * @throws ApiError
   */
  public static getAnnotatedFields(): CancelablePromise<
    Array<AnnotationFieldDto>
  > {
    return __request(OpenAPI, {
      method: "GET",
      url: "/admin/annotated-fields",
    });
  }

  /**
   * Delete annotated fields data
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static deleteAnnotatedFieldData(id: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/admin/annotated-fields/{id}",
      path: {
        id: id,
      },
    });
  }
}
