import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div<{ $hoverEffect: boolean }>`
  flex: 1;
  position: relative;
  border-bottom: 1px solid ${theme.color.gray[300]};
  padding: ${theme.spacing.md} ${theme.spacing.xl};
  transition: background-color 0.25s;
  ${({ $hoverEffect }) =>
    $hoverEffect &&
    `
  &:hover {
    background-color: ${theme.color.blue[100]};
    .document-buttons {
      opacity: 1;
      visibility: visible;
    }
  }`}
`;

export const Title = styled.div`
  font-size: ${theme.font.size.subheading};
  cursor: pointer;
`;

export const Details = styled.div`
  color: ${theme.color.gray[500]};
  font-size: ${theme.font.size.caption};
`;

export const Buttons = styled.div`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${theme.spacing.sm};
  transition: all 0.25s;
`;

export const ContentSpan = styled.span<{
  $shouldAddContentBefore?: boolean;
}>`
  position: relative;
  margin-left: ${theme.spacing.sm};
  padding-left: ${theme.spacing.md};
  &:before {
    ${({ $shouldAddContentBefore }) =>
      $shouldAddContentBefore &&
      `
    content: "";
    position: absolute;
    width: 4px;
    left: 0;
    top: 50%;
    height: 4px;
    border-radius: 50%;
    background-color: ${theme.color.gray[500]};
    transform: translateY(-50%);`}
  }
`;
