import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useSnackbar } from "notistack";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import {
  ContractAttachmentService,
  ContractNameDto,
  OrganizationService,
} from "openapi";
import { useTeam } from "contexts/team/hooks";
import VerifyAllBanner from "./components/VerifyAllBanner/VerifyAllBanner";
import routePaths from "constants/routePaths";
import { Features } from "constants/";
import RightSide from "./components/RightSide/RightSide";
import { PrintProvider } from "contexts/contract/context";
import { useOrganizationCategoriesQuery } from "shared/api/organization/categories";
import { useFieldsQuery } from "shared/api/fields";
import CircularLoading from "components/CircularLoading/CircularLoading";
import ContractData from "./components/ContractData/ContractData";
import { Header, Toolbar } from "./components";
import { ContractGrid, ContractColumn } from "./styles";
import { PDFViewerActionsProvider } from "components/PDFViewer/PDFViewerActionContext";
import { useContractQuery } from "shared/api";
import { ContractDetailsProvider } from "./context";

const Contract = () => {
  const {
    permissionSet,
    setSelectedTeam,
    selectedTeamId,
    organizationId,
    hasFeature,
  } = useTeam();
  const { data: categories } = useOrganizationCategoriesQuery(organizationId);
  const { data: fields } = useFieldsQuery(organizationId);
  const { id, mode } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [contractFileBlob, setContractFile] = useState<Blob>();
  const [contractGroup, setContractGroup] = useState<ContractNameDto[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { data: contract, refetch } = useContractQuery(selectedTeamId, id);
  const navigate = useNavigate();
  const [isContractDataFetching, setIsContractDataFetching] = useState(false);
  const printRef = useRef(null);

  const editable = mode === "edit";

  const category = useMemo(
    () => categories?.find((category) => category.id === contract?.categoryId),
    [categories, contract]
  );

  const fetchData = async () => {
    await refetch();
    await fetchContractGroup();
  };

  const fetchContractGroup = async () => {
    if (!contract) {
      return;
    }
    try {
      setIsContractDataFetching(true);
      setIsLoading(false);
      const updatedContract = await OrganizationService.getContractOfTeamById(
        selectedTeamId,
        contract.id
      );
      const contractGroup = await ContractAttachmentService.getContractsOfGroup(
        updatedContract.parentId ?? updatedContract.id
      );
      setContractGroup(contractGroup);
      await fetchPdf();
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      setIsContractDataFetching(false);
    }
  };

  const fetchPdf = async () => {
    if (id) {
      setContractFile(
        (await ContractAttachmentService.downloadContract(id)) as Blob
      );
    } else {
      enqueueSnackbar("Contract Id missing", { variant: "error" });
    }
  };

  useEffect(() => {
    void fetchContractGroup();
  }, [contract?.id]);

  useEffect(() => {
    if (contract && !permissionSet?.[contract.teamId]) {
      void setSelectedTeam(contract.teamId);
    }
  }, [contract]);

  /**
   * handles the back button click
   *
   * In case of view mode, get back to the contracts
   * In case of edit mode, check if the form is dirty to prevent page change
   */
  function handleClick() {
    if (editable) {
      navigate("..", { relative: "path" });
    } else {
      navigate(routePaths.CONTRACTS);
    }
  }

  return (
    <ContractDetailsProvider>
      <PrintProvider title={contract?.name}>
        <div>
          <CircularLoading isLoading={isLoading} />
          {contract && category && fields && (
            <>
              <Header
                editable={editable}
                handleClick={handleClick}
                toolbar={
                  <Toolbar
                    contract={contract}
                    fetchData={fetchData}
                    fetchPdf={fetchPdf}
                    contractGroup={contractGroup}
                    contractHasPDFFile={!!contractFileBlob}
                    contractFile={contractFileBlob}
                    editable={editable}
                    setContractFile={setContractFile}
                  />
                }
              />
              <PDFViewerActionsProvider>
                <ContractGrid>
                  <ContractColumn>
                    {hasFeature(Features.CONTRACT_ANALYSIS) && (
                      <VerifyAllBanner
                        contract={contract}
                        refetch={fetchData}
                      />
                    )}
                    <OverlayScrollbarsComponent
                      defer
                      style={{ maxHeight: "100%" }}
                    >
                      <ContractData
                        isLoading={isLoading}
                        contract={contract}
                        isContractDataFetching={isContractDataFetching}
                        category={category}
                        fields={fields}
                        fetchData={fetchData}
                        contractGroup={contractGroup}
                        ref={printRef}
                      />
                    </OverlayScrollbarsComponent>
                  </ContractColumn>
                  <ContractColumn>
                    <RightSide
                      contractFileBlob={contractFileBlob}
                      fetchData={fetchPdf}
                      refetch={fetchData}
                      isContractDataFetching={isContractDataFetching}
                      contract={contract}
                      contractGroup={contractGroup}
                    />
                  </ContractColumn>
                </ContractGrid>
              </PDFViewerActionsProvider>
            </>
          )}
        </div>
      </PrintProvider>
    </ContractDetailsProvider>
  );
};

export default Contract;
