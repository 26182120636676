import React, { useEffect, useMemo, useState } from "react";
import { ModalResultEnum } from "components/Modal/Modal";
import { setValidationErrors } from "shared/service/errorResponseService";
import { CTAButton, FormTextField, NewModal } from "components";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { ContractViewInputDto, ContractViewItemDto } from "openapi";
import { AgGridReact } from "ag-grid-react";
import { Switch } from "new-components";
import { DeleteButton, SwitcherWrapper, Form, ModalBody } from "./styles";

const initialValues = {
  name: "",
  shared: false,
};

type Props = {
  open: boolean;
  selectedView: ContractViewItemDto | null;
  gridRef: React.MutableRefObject<AgGridReact | undefined>;
  filterModelGetter: () => string | null;
  getFilterModelAsJson: () => string | null;
  createView: (viewData: Omit<ContractViewInputDto, "type">) => Promise<void>;
  updateView: (viewData: Omit<ContractViewItemDto, "owner">) => Promise<void>;
  setShowSaveOrEditViewModal: React.Dispatch<React.SetStateAction<boolean>>;
  deleteView?: (viewData: ContractViewItemDto) => void;
};

const ContractSaveOrEditViewModal = ({
  open,
  selectedView,
  gridRef,
  filterModelGetter,
  getFilterModelAsJson,
  updateView,
  setShowSaveOrEditViewModal,
  createView,
  deleteView,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [shared, setShared] = useState<boolean>(false);

  const { control, getValues, setValue, watch, reset, setError } = useForm({
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (selectedView) {
      setValue("name", selectedView.name);
      setShared(selectedView?.shared);
    } else {
      reset(initialValues);
    }
  }, [selectedView]);

  const handleSaveOrEditViewModalClose = async (
    action?: ModalResultEnum,
    values?: unknown
  ) => {
    try {
      if (action === ModalResultEnum.SAVE) {
        const columnStateAsJson = JSON.stringify(
          gridRef.current?.columnApi.getColumnState()
        );
        const name = (values as { name: string }).name;
        await createView({
          name: name,
          data: columnStateAsJson,
          filter: filterModelGetter(),
          shared: shared,
        });
      } else if (action === ModalResultEnum.EDIT && selectedView) {
        const viewData = {
          id: selectedView.id,
          name: (values as { name: string }).name,
          data: selectedView.data,
          filter: getFilterModelAsJson(),
          type: selectedView.type,
          shared: shared,
        };
        await updateView(viewData);
      }
      reset(initialValues);
      setShowSaveOrEditViewModal(false);
    } catch (e) {
      setValidationErrors(
        e,
        setError,
        "pages.contracts.header.modals.saveViewModal",
        undefined,
        enqueueSnackbar,
        t
      );
    }
  };

  const handleKeyboardDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSubmit();
    }
  };

  const handleModalClose = () => {
    void handleSaveOrEditViewModalClose(ModalResultEnum.CANCEL);
  };

  const handleDeleteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!selectedView?.id) return;
    e.preventDefault();
    e.stopPropagation();

    const deleteHandler = deleteView
      ? (
          type: ModalResultEnum.DELETE,
          id: ContractViewItemDto | string | null
        ) => deleteView(selectedView)
      : () => null;
    deleteHandler && deleteHandler(ModalResultEnum.DELETE, selectedView.id);
  };

  const onSubmit = () => {
    void handleSaveOrEditViewModalClose(
      !selectedView ? ModalResultEnum.SAVE : ModalResultEnum.EDIT,
      getValues()
    );
  };

  const saveButtonDisabled = useMemo(() => {
    return (
      (selectedView?.name === watch().name &&
        selectedView?.shared === shared) ||
      !watch().name
    );
  }, [shared, watch().name]);

  return (
    <NewModal
      open={open}
      handleClose={handleModalClose}
      fullWidth
      title={
        !selectedView
          ? t("pages.contracts.header.modals.saveViewModal.title")
          : t("pages.contracts.header.modals.editViewModal.title")
      }
      body={
        <ModalBody>
          <div>
            {!selectedView
              ? t("pages.contracts.header.modals.saveViewModal.subtitle")
              : t("pages.contracts.header.modals.editViewModal.subtitle")}
          </div>
          <Form name="contractSaveOrEditView" noValidate>
            <FormTextField
              control={control}
              name="name"
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
                handleKeyboardDown(e)
              }
              label={
                !selectedView
                  ? t("pages.contracts.header.modals.saveViewModal.name")
                  : t("pages.contracts.header.modals.editViewModal.name")
              }
            />

            <SwitcherWrapper>
              <Switch
                checked={shared}
                onChange={() => {
                  setShared(!shared);
                }}
                name="shared"
                label={t("pages.contracts.header.modals.editViewModal.share")}
                labelPosition="left"
                stretch
              />
            </SwitcherWrapper>
          </Form>
        </ModalBody>
      }
      footer={
        <>
          {selectedView && (
            <DeleteButton
              label="delete-view"
              name={t("pages.contracts.header.modals.buttons.deleteView")}
              variant="secondary"
              color="red"
              onClick={(e) => handleDeleteClick(e)}
            />
          )}
          <CTAButton
            label="cancel"
            name={t("pages.contracts.header.modals.buttons.cancel")}
            variant="secondary"
            onClick={() => {
              handleModalClose();
            }}
          />
          <CTAButton
            label="save-view"
            type="submit"
            name={t("pages.contracts.header.modals.buttons.save")}
            variant="primary"
            onClick={() => onSubmit()}
            disabled={saveButtonDisabled}
          />
        </>
      }
    />
  );
};

export default ContractSaveOrEditViewModal;
