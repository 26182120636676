import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection, $isRangeSelection } from "lexical";
import React, { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { FloatingPlaceholderPopup } from "../components/FloatingPlaceholderPopup";
import { mergeRegister } from "@lexical/utils";
import { $isPlaceholderNode } from "../nodes/PlaceholderTextNode";
import { getSelectedNode } from "../utils";

type FloatingPlaceholderPopupPluginProps = {
  anchorEl: HTMLElement;
  getLabelBySlug: (slug: string) => string | null;
};

export const FloatingPlaceholderPopupPlugin = ({
  anchorEl,
  getLabelBySlug,
}: FloatingPlaceholderPopupPluginProps) => {
  const [editor] = useLexicalComposerContext();
  const [selectedPlaceholderNode, setSelectedPlaceholderNode] = useState<
    string | null
  >(null);

  const updatePopup = useCallback(() => {
    editor.getEditorState().read(() => {
      if (editor.isEditable()) {
        return;
      }

      if (editor.isComposing()) {
        return;
      }

      const selection = $getSelection();
      if (!$isRangeSelection(selection)) {
        setSelectedPlaceholderNode(null);
        return;
      }

      const node = getSelectedNode(selection);
      setSelectedPlaceholderNode(
        $isPlaceholderNode(node) ? node.getKey() : null
      );
    });
  }, [editor]);

  useEffect(() => {
    document.addEventListener("selectionchange", updatePopup);
    return () => {
      document.removeEventListener("selectionchange", updatePopup);
    };
  }, [updatePopup]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(() => {
        updatePopup();
      }),
      editor.registerRootListener(() => {
        if (editor.getRootElement() === null) {
          setSelectedPlaceholderNode(null);
        }
      }),
      editor.registerEditableListener((editable) => {
        if (editable) {
          setSelectedPlaceholderNode(null);
        }
      })
    );
  }, [editor, updatePopup]);

  return selectedPlaceholderNode
    ? createPortal(
        <FloatingPlaceholderPopup
          editor={editor}
          nodeKey={selectedPlaceholderNode}
          anchorEl={anchorEl}
          getLabelBySlug={getLabelBySlug}
        />,
        anchorEl
      )
    : null;
};
