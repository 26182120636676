import React, { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { ImagePreview, Overlay, NoContent } from "./styles";
import { ContractTemplateService } from "openapi";
import { CircularProgress } from "@mui/material";

const Preview = ({
  onClick,
  templateId,
  organizationId,
}: {
  templateId: string;
  organizationId: string;
  onClick: () => void;
}) => {
  const { t } = useTranslation();
  const [imageFile, setImageFile] = useState<Blob | undefined>(undefined);
  const [fileURL, setFileURL] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (templateId) {
      const loadImage = async () => {
        setLoading(true);
        try {
          const blob = (await ContractTemplateService.getImage(
            templateId,
            organizationId
          )) as Blob;
          setImageFile(blob);
        } catch (error) {
          console.error("Error fetching templaye preview image:", error);
        } finally {
          if (imageFile === undefined) {
            setLoading(false);
          }
        }
      };

      void loadImage();
    }
  }, [templateId]);

  useEffect(() => {
    if (!imageFile) {
      return;
    }
    const file = new Blob([imageFile], { type: "image/png" });
    setFileURL(URL.createObjectURL(file));
    setLoading(false);

    return () => {
      if (fileURL) {
        setFileURL(null);
        URL.revokeObjectURL(fileURL);
      }
    };
  }, [imageFile]);

  return (
    <ImagePreview onClick={onClick} role="button">
      {loading ? (
        <CircularProgress />
      ) : fileURL ? (
        <img src={fileURL} alt="template-preview" />
      ) : (
        <NoContent>
          <span>
            {t(
              "pages.settings.tabs.contractTemplates.gallery.templateCard.noContent"
            )}
          </span>
        </NoContent>
      )}
      <Overlay />
    </ImagePreview>
  );
};

export default memo(Preview);
