import styled from "@emotion/styled";
import { theme } from "theme";

type ButtonProps = {
  disabled?: boolean;
  active?: boolean;
};

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  gap: ${theme.spacing.sm};
  width: 20px;
  height: 20px;
  color: ${theme.color.gray[700]};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  border-radius: 4px;
  background-color: ${(props) =>
    props.active ? theme.color.blue[100] : "transparent"};
  padding: 0;
  transition: all 0.2s;
  &:hover {
    background-color: ${theme.color.blue[100]};
  }
  svg {
    width: 16px;
    height: 16px;
    fill: currentColor;
  }
`;
