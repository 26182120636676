import React from "react";
import { TravelExplore } from "@mui/icons-material";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Tooltip,
} from "@mui/material";
import { theme } from "theme";
import { AnalysisFieldData, GoodAnalysisData } from "../types";
import { usePDFViewerActionsDispatch } from "components/PDFViewer/PDFViewerActionContext";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Tag from "new-components/Tag";
import { useUserInfo } from "hooks";
import { ContractFieldDTOV1 } from "openapi";
dayjs.extend(customParseFormat);

export type AnalysisWrapperProps = {
  visibleId: string | null;
  fieldKey: string;
  data: AnalysisFieldData;
  definitionType: ContractFieldDTOV1.type;
  converter: (value: string) => string | number | null | undefined;
  selectAnalysisValue: (item: GoodAnalysisData) => void;
};

const AnalysisSuggestions = ({
  visibleId,
  fieldKey,
  data,
  definitionType,
  selectAnalysisValue,
  converter,
}: AnalysisWrapperProps) => {
  const { jumpToOffset } = usePDFViewerActionsDispatch();
  const { userInfo } = useUserInfo();
  const userDateFormat = userInfo?.dateFormat;

  if (!data) {
    return null;
  }

  const getConvertedValue = (data: GoodAnalysisData) => {
    try {
      const value = converter(data.value);
      if (definitionType === ContractFieldDTOV1.type.DATE) {
        const date = dayjs(value, "YYYY-MM-DD");
        if (date.isValid()) {
          return date.format(userDateFormat);
        }
      }
      return value;
    } catch (e) {
      return data.value;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Tooltip
        components={{ Tooltip: Box }}
        leaveDelay={150}
        title={
          <Paper
            sx={{
              boxShadow: theme.shadow.standard,
              overflowY: "auto",
              maxHeight: "200px",
            }}
          >
            {data.items ? (
              data.items.map((item, idx) => (
                <MenuItem
                  dense
                  key={idx}
                  onClick={() => {
                    selectAnalysisValue(item);
                  }}
                >
                  <ListItemIcon>
                    <IconButton
                      disabled={!item.beginOffset}
                      onClick={() =>
                        jumpToOffset({
                          beginOffset: data.BeginOffset,
                          visibleId: visibleId as string,
                          fieldKey: fieldKey,
                        })
                      }
                    >
                      <TravelExplore />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText
                    primary={<>{getConvertedValue(item)}</>}
                    secondary={`Confidence: ${item.confidence.toFixed(
                      2
                    )}, Source: ${item.origin}`}
                  />
                </MenuItem>
              ))
            ) : (
              <></>
            )}
          </Paper>
        }
      >
        <IconButton sx={{ width: "35px", height: "35px" }}>
          <Tag variant="ai" />
        </IconButton>
      </Tooltip>
    </div>
  );
};
export default AnalysisSuggestions;
