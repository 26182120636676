import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Tooltip, CircularProgress } from "@mui/material";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { ColorPoint } from "../components/Header/components/CategorySelector/styles";
import { useOrganizationCategoriesQuery } from "shared/api/organization/categories";
import {
  useContractTemplatesQueryV1,
  useGetAllContractTemplateTagsQuery,
} from "shared/api/contract-templates";
import {
  categoriesTeamSelector,
  getDefaultCategory,
} from "shared/api/organization/categories.helpers";
import { useTeam } from "contexts/team/hooks";
import { useLocale } from "hooks";
import {
  CategoryDTO,
  ContractInputDTOV1,
  ContractTemplateTagDTO,
  ContractTemplateV1DTO,
  OrganizationService,
  ContractTemplateService,
  ContractAttachmentService,
  ContractDTOV1,
} from "openapi";
import { TextField, Tag } from "new-components";
import routePaths from "constants/routePaths";
import { TemplateCard } from "./components";
import { ContractTemplateMode } from "../types";
import {
  AllButton,
  Filters,
  FilterSection,
  FilterTitle,
  List,
  ListItem,
  Container,
  SearchBlock,
  TemplatesContainer,
  TemplatesGallery,
  CreateTemplateCard,
  CreateNewCaption,
  CreateNewButton,
  TagList,
  Divider,
  Description,
  NoTemplatesMessage,
} from "./styles";

const TemplateCollection = ({
  mode = ContractTemplateMode.EDIT,
  handleClose,
  contractId,
  contract,
}: {
  mode?: ContractTemplateMode;
  handleClose?: () => void;
  contractId?: string;
  contract?: ContractDTOV1;
}) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const navigate = useNavigate();
  const { isOwner, organizationId, selectedTeamId, hasWriteAccess } = useTeam();
  const { data: categories } = useOrganizationCategoriesQuery(
    organizationId,
    (categories) =>
      categories.filter((category) =>
        categoriesTeamSelector(category, selectedTeamId)
      )
  );
  const getPermittedCategories = () => {
    if (isOwner()) {
      return categories;
    }
    const teamId = contract?.teamId || selectedTeamId;

    return categories?.filter((category) => {
      return (
        category.teams.includes(teamId) &&
        hasWriteAccess(category.id, selectedTeamId)
      );
    });
  };

  const permittedCategories = getPermittedCategories();

  const { data: tags } = useGetAllContractTemplateTagsQuery(organizationId);
  const { data: templates } = useContractTemplatesQueryV1(organizationId);

  const isEditable = mode === ContractTemplateMode.EDIT;

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(false);

  const filteredTemplates = useMemo(() => {
    return templates?.filter((template) => {
      const filters = [
        () =>
          selectedCategories.length === 0 ||
          selectedCategories.includes(template.categoryId),
        () =>
          selectedTags.length === 0 ||
          template.tags.some((tag) => selectedTags.includes(tag.id)),
        () =>
          !searchTerm ||
          template.name.toLowerCase().includes(searchTerm.toLowerCase()),
      ];
      return filters.every((filter) => filter());
    });
  }, [templates, selectedCategories, selectedTags, searchTerm]);

  const resetFilters = () => {
    setSelectedCategories([]);
    setSelectedTags([]);
    setSearchTerm("");
  };

  const searchByName = (term: string) => setSearchTerm(term);

  const toggleCategory = (category: CategoryDTO) => {
    setSelectedCategories((prev) =>
      prev.includes(category.id)
        ? prev.filter((id) => id !== category.id)
        : [...prev, category.id]
    );
  };

  const toggleTag = (tag: ContractTemplateTagDTO) => {
    setSelectedTags((prev) =>
      prev.includes(tag.id)
        ? prev.filter((id) => id !== tag.id)
        : [...prev, tag.id]
    );
  };

  const applyTemplateToContract = async (
    contractId: string,
    template: ContractTemplateV1DTO
  ) => {
    try {
      await ContractAttachmentService.uploadFileForContract(contractId, {
        file: new File(
          [
            JSON.stringify({
              templateId: template.id,
              content: {
                lexical: template.content,
              },
            }),
          ],
          `${template.name}.draft`,
          { type: "contractHero/lexical" }
        ),
      });
    } catch (error) {
      enqueueSnackbar(t("response.message.error.apply_template_to_contract"), {
        variant: "error",
      });
    }
  };

  const createContractFromTemplate = async (
    template: ContractTemplateV1DTO
  ) => {
    const isTeamMatched = (item: CategoryDTO, selectedTeamId: string) => {
      return item.teams.includes(selectedTeamId);
    };

    try {
      if (!permittedCategories) return;

      const category =
        getDefaultCategory(categories, selectedTeamId) ||
        permittedCategories.find((item) => isTeamMatched(item, selectedTeamId));

      const draftContract = await OrganizationService.createContract(
        selectedTeamId,
        {
          name: t("pages.contractDetails.general.newContractName"),
          status: ContractInputDTOV1.status.DRAFT,
          categoryId: category?.id,
        }
      );

      if (!draftContract.id) return;

      await applyTemplateToContract(draftContract.id, template);

      enqueueSnackbar(
        t("response.message.success.create_contract_with_template"),
        {
          variant: "success",
        }
      );

      return draftContract;
    } catch (error) {
      enqueueSnackbar(t("response.message.error.create_contract"), {
        variant: "error",
      });
    }
  };

  const handleOnTemplateCardClick = async (template: ContractTemplateV1DTO) => {
    // @Todo: Remove when all contract template features are ready deployed
    if (mode === ContractTemplateMode.TEMPORARY_SELECT) {
      if (!contractId) return;

      try {
        setIsLoadingTemplate(true);
        const pdf = (await ContractTemplateService.renderContractTemplate(
          organizationId,
          {
            editorState: template.content,
          }
        )) as Blob;

        if (!contractId || !pdf) return;

        await ContractAttachmentService.uploadFileForContract(contractId, {
          file: pdf,
        });

        handleClose?.();
        setIsLoadingTemplate(false);
      } catch (error) {
        enqueueSnackbar(
          t("response.message.error.create_contract_with_template"),
          {
            variant: "error",
          }
        );
      }
    }

    // @Note: Create draft contract from existing template (user)
    if (mode === ContractTemplateMode.PRESELECT) {
      try {
        const newContractWithTemplate = await createContractFromTemplate(
          template
        );

        handleClose?.();

        if (newContractWithTemplate) {
          navigate(
            `${routePaths.CONTRACTS}/${newContractWithTemplate.id}/edit`
          );
        }

        return;
      } catch (error) {
        enqueueSnackbar(
          t("response.message.error.create_contract_with_template"),
          {
            variant: "error",
          }
        );
      }
    }

    // @Note: Select and apply template from collection to an existing contract without document (user)
    if (mode === ContractTemplateMode.SELECT) {
      if (!contractId) return;
      void applyTemplateToContract(contractId, template);
      navigate(`${routePaths.CONTRACTS}/${contractId}/edit`);
      handleClose?.();
    }

    // @Note: Navigate to selected template (admin)
    if (isEditable) {
      navigate(`${routePaths.SETTINGS_CONTRACT_TEMPLATES}/${template.id}`);
      return;
    }

    return;
  };

  const hasTemplatesAvailable =
    filteredTemplates && filteredTemplates?.length > 0;

  if (isLoadingTemplate) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "80%",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Container>
      <Filters>
        <FilterSection>
          <FilterTitle>
            {t(
              "pages.settings.tabs.contractTemplates.gallery.filters.title.templates"
            )}
          </FilterTitle>
          <div className="all">
            <AllButton
              name={t(
                "pages.settings.tabs.contractTemplates.gallery.filters.title.all"
              )}
              onClick={resetFilters}
              size="stretched"
            />
          </div>
        </FilterSection>

        <FilterSection>
          <FilterTitle>
            {t(
              "pages.settings.tabs.contractTemplates.gallery.filters.title.categories"
            )}
          </FilterTitle>
          <OverlayScrollbarsComponent defer style={{ overflowY: "auto" }}>
            <List>
              {categories?.map((category) =>
                category.name[locale].length < 19 ? (
                  <ListItem
                    key={category.id}
                    onClick={() => toggleCategory(category)}
                    style={{
                      fontWeight: selectedCategories.includes(category.id)
                        ? "bold"
                        : "normal",
                    }}
                  >
                    <ColorPoint color={category.color} />
                    <span>{category.name[locale]}</span>
                  </ListItem>
                ) : (
                  <Tooltip title={category.name[locale]} key={category.id}>
                    <ListItem
                      onClick={() => toggleCategory(category)}
                      style={{
                        fontWeight: selectedCategories.includes(category.id)
                          ? "bold"
                          : "normal",
                      }}
                    >
                      <ColorPoint color={category.color} />
                      <span>{category.name[locale]}</span>
                    </ListItem>
                  </Tooltip>
                )
              )}
            </List>
          </OverlayScrollbarsComponent>
        </FilterSection>

        <Divider />

        <FilterSection>
          <FilterTitle>
            {t(
              "pages.settings.tabs.contractTemplates.gallery.filters.title.tags"
            )}
          </FilterTitle>
          <OverlayScrollbarsComponent>
            <List>
              <TagList>
                {tags?.map((tag) => (
                  <div
                    key={tag.id}
                    onClick={() => toggleTag(tag)}
                    className="tag"
                  >
                    <Tag
                      variant={
                        selectedTags.includes(tag.id) ? "selected" : "tag"
                      }
                    >
                      {tag.name}
                    </Tag>
                  </div>
                ))}
              </TagList>
            </List>
          </OverlayScrollbarsComponent>
        </FilterSection>
      </Filters>

      <OverlayScrollbarsComponent style={{ width: "100%" }}>
        <TemplatesContainer>
          <Description>
            {isEditable
              ? t(
                  "pages.settings.tabs.contractTemplates.gallery.description.edit"
                )
              : t(
                  "pages.settings.tabs.contractTemplates.gallery.description.apply"
                )}
          </Description>

          <SearchBlock>
            <TextField
              fullWidth
              name="search-contract-template"
              onChange={(event) => searchByName(event.target.value)}
              placeholder={t(
                "pages.settings.tabs.contractTemplates.gallery.searchBar.placeholder"
              )}
              value={searchTerm}
              disabled={!hasTemplatesAvailable}
            />
          </SearchBlock>

          <h3>{t("pages.settings.tabs.contractTemplates.gallery.title")}</h3>

          <TemplatesGallery>
            {isEditable && (
              <CreateTemplateCard
                onClick={() =>
                  navigate(`${routePaths.SETTINGS_CONTRACT_TEMPLATES}/new`)
                }
              >
                <CreateNewButton>+</CreateNewButton>
                {t(
                  "pages.settings.tabs.contractTemplates.gallery.createTemplateCard.title"
                )}
                <CreateNewCaption>
                  {t(
                    "pages.settings.tabs.contractTemplates.gallery.createTemplateCard.caption"
                  )}
                </CreateNewCaption>
              </CreateTemplateCard>
            )}

            {hasTemplatesAvailable ? (
              filteredTemplates?.map((template) => {
                const accessibleTemplate = (
                  isEditable ? categories : permittedCategories
                )?.find((cat) => cat.id === template.categoryId);

                if (!accessibleTemplate) return null;

                return (
                  <TemplateCard
                    isEditable={isEditable}
                    key={template.id}
                    template={{
                      ...template,
                      content:
                        (template as ContractTemplateV1DTO)?.content ?? "",
                    }}
                    categories={isEditable ? categories : permittedCategories}
                    onClick={() => handleOnTemplateCardClick(template)}
                  />
                );
              })
            ) : (
              <NoTemplatesMessage>
                {t("pages.settings.tabs.contractTemplates.gallery.unavailable")}
              </NoTemplatesMessage>
            )}
          </TemplatesGallery>
        </TemplatesContainer>
      </OverlayScrollbarsComponent>
    </Container>
  );
};

export default TemplateCollection;
