import styled from "@emotion/styled";
import { theme } from "theme";

const IconWrapper = styled.div<{
  color?: string;
  bgColor?: string;
  size?: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    ${({ color, bgColor, size }) => `
      fill: ${color || theme.color.gray[700]};
      background-color: ${bgColor || "transparent"};
      width: ${size || 20}px;
      height: ${size || 20}px;
    `};
  }
`;

export default IconWrapper;
