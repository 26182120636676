import {
  $applyNodeReplacement,
  EditorConfig,
  LexicalNode,
  SerializedTextNode,
  Spread,
  TextNode,
} from "lexical";
import { addClassNamesToElement } from "@lexical/utils";

export enum ValueOrigins {
  Internal = "internal",
  External = "external",
}

export type SerializedPlaceholderNode = Spread<
  {
    slug: string;
    fieldKey: string;
  },
  SerializedTextNode
>;

const TOKEN_MODE = 1;

export class PlaceholderTextNode extends TextNode {
  static getType(): string {
    return "placeholder-text";
  }

  static clone(node: PlaceholderTextNode): PlaceholderTextNode {
    return new PlaceholderTextNode(
      node.__slug,
      node.__fieldKey,
      node.__text,
      node.__key
    );
  }

  static getDefaultText(slug: string, fieldKey: string): string {
    if (fieldKey !== "value") {
      return `${slug} (${fieldKey})`;
    }
    return slug;
  }

  constructor(
    public readonly __slug: string,
    public readonly __fieldKey: string,
    text?: string,
    key?: string
  ) {
    super(text ?? PlaceholderTextNode.getDefaultText(__slug, __fieldKey), key);
    this.__mode = TOKEN_MODE;
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = super.createDOM(config);
    addClassNamesToElement(element, "placeholder-text");
    //element.contentEditable = "false";
    return element;
  }

  static importJSON(
    serializedNode: SerializedPlaceholderNode
  ): PlaceholderTextNode {
    const node = $createPlaceholderTextNode(
      serializedNode.slug,
      serializedNode.fieldKey,
      serializedNode.text
    );
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);

    return node;
  }

  getSlug(): string {
    return this.__slug;
  }

  getFieldKey(): string {
    return this.__fieldKey;
  }

  isTextEntity(): boolean {
    return true;
  }

  canInsertTextBefore(): boolean {
    return false;
  }

  canInsertTextAfter(): boolean {
    return false;
  }

  exportJSON(): SerializedPlaceholderNode {
    return {
      ...super.exportJSON(),
      slug: this.__slug,
      fieldKey: this.__fieldKey,
      type: "placeholder-text",
      version: 1,
    };
  }
}

export function $createPlaceholderTextNode(
  slug: string,
  fieldKey: string,
  text?: string
): PlaceholderTextNode {
  return $applyNodeReplacement(new PlaceholderTextNode(slug, fieldKey, text));
}

export function $isPlaceholderNode(
  node: LexicalNode | null | undefined
): node is PlaceholderTextNode {
  return node instanceof PlaceholderTextNode;
}
