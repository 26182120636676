import { AiSuggestion, DetailNameWrapper } from "components";
import Tag from "new-components/Tag";
import React, { useMemo, useState } from "react";
import {
  DatapointFieldActions,
  DatapointFieldContainer,
  DatapointFieldContent,
  DatapointFieldFooter,
  DatapointFieldHeader,
} from "./styles";
import VerifyTag from "pages/Contracts/components/VerifyTag/VerifyTag";
import { FieldKey, Suggestions } from "../hooks/types";
import { usePDFViewerActionsDispatch } from "components/PDFViewer/PDFViewerActionContext";
import { TagWrapper } from "components/AiSuggestion/styles";
import { ContractDTOV1, ContractFieldDTOV1 } from "openapi";
import {
  ContactDataDTO,
  ContactDatapointDTOs,
} from "pages/Contacts/ContactDataDTO";

type DatapointFieldProps<T extends ContractDTOV1["fields"][string]> = {
  definition: ContractFieldDTOV1;
  field: FieldKey<T>;
  data: T | undefined;
  name: string;
  suggestions?: Record<string, Suggestions> | null | undefined;
  children: React.ReactNode;
  onVerify?: () => void | Promise<void>;
  onSelectInsight?: (value: string) => void | Promise<void>;
  contacts?: ContactDataDTO[];
};

export const DatapointField = <T extends ContractDTOV1["fields"][string]>({
  definition,
  data,
  field,
  name,
  suggestions,
  children,
  onVerify,
  onSelectInsight,
  contacts,
}: DatapointFieldProps<T>) => {
  const [recentlyVerified, setRecentlyVerified] = useState(false);
  const [hovering, setHovering] = useState(false);
  const { jumpToOffset } = usePDFViewerActionsDispatch();

  const metadata = data?.metadata;

  const fieldMetadata = metadata?.[field];
  const fieldSuggestions = suggestions?.[field];

  const handleVerify = async () => {
    if (onVerify) {
      await onVerify();
      setRecentlyVerified(true);
      setTimeout(() => {
        setRecentlyVerified(false);
      }, 10000);
    }
  };

  const showSuggestions = !!suggestions && !!fieldMetadata?.unverified;
  const jumpOffset = useMemo(
    () =>
      fieldSuggestions?.find((suggestion) => {
        if (definition.type === "CONTACT") {
          const contact = contacts?.find(
            (contact) =>
              contact.id === (data as Record<string, unknown>)?.[field]
          );
          return (
            contact &&
            (contact.name as ContactDatapointDTOs)?.value?.value ===
              suggestion?.value
          );
        }
        return suggestion?.value === (data as Record<string, unknown>)?.[field];
      })?.beginOffset,
    [suggestions, data]
  );

  const hoverable = showSuggestions || !!jumpOffset;

  const jumpTo = () => {
    if (jumpOffset) {
      jumpToOffset({
        beginOffset: jumpOffset,
        fieldKey: field,
        visibleId: definition.visibleId as string,
      });
    }
  };

  const datapointField = (
    <DatapointFieldContainer
      onMouseEnter={() => setHovering(hoverable)}
      onMouseLeave={() => setHovering(false)}
      onClick={jumpTo}
      hoverable={hoverable}
      jumpable={!!jumpOffset}
    >
      <DatapointFieldHeader>
        <DetailNameWrapper>{name}</DetailNameWrapper>
        <DatapointFieldActions>
          {showSuggestions ? <Tag variant="ai" /> : null}
          {jumpOffset ? (
            <TagWrapper>
              <Tag variant="search-doc" />
            </TagWrapper>
          ) : null}
        </DatapointFieldActions>
      </DatapointFieldHeader>
      <DatapointFieldFooter>
        <DatapointFieldContent data-testid="value">
          {children}
        </DatapointFieldContent>
        <VerifyTag
          verified={recentlyVerified}
          invisible={
            !recentlyVerified && (!hovering || !fieldMetadata?.unverified)
          }
          onClick={handleVerify}
        />
      </DatapointFieldFooter>
    </DatapointFieldContainer>
  );

  return showSuggestions && !!fieldSuggestions ? (
    <AiSuggestion
      options={fieldSuggestions}
      handleOptionClick={(option) => {
        void onSelectInsight?.(option as string);
      }}
    >
      {datapointField}
    </AiSuggestion>
  ) : (
    datapointField
  );
};
