import React, { createContext, useState, useContext, ReactNode } from "react";

interface TemplateContextType {
  importedFile: File | null;
  setImportedFile: (file: File | null) => void;
}

export const TemplateContext = createContext<TemplateContextType | undefined>(
  undefined
);

export const TemplateProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [importedFile, setImportedFile] = useState<File | null>(null);

  return (
    <TemplateContext.Provider
      value={{
        importedFile,
        setImportedFile,
      }}
    >
      {children}
    </TemplateContext.Provider>
  );
};

export const useTemplate = () => {
  const context = useContext(TemplateContext);
  if (context === undefined) {
    throw new Error("useTemplate must be used within a TemplateProvider");
  }
  return context;
};
