/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AmountFieldTypeDtoV1 } from "./AmountFieldTypeDtoV1";
import type { AnalysisCategoryMappingDto } from "./AnalysisCategoryMappingDto";
import type { AnalysisDataDto } from "./AnalysisDataDto";
import type { AnalysisFieldMappingDto } from "./AnalysisFieldMappingDto";
import type { AutosaveDTO } from "./AutosaveDTO";
import type { CategoryDTOV1 } from "./CategoryDTOV1";
import type { ContactDatapointDefinitionDTO } from "./ContactDatapointDefinitionDTO";
import type { ContactTypeDTO } from "./ContactTypeDTO";
import type { ContractCategoryDto } from "./ContractCategoryDto";
import type { ContractFieldDTOV1 } from "./ContractFieldDTOV1";
import type { CountryFieldTypeDtoV1 } from "./CountryFieldTypeDtoV1";
import type { DateFieldTypeDtoV1 } from "./DateFieldTypeDtoV1";
import type { DurationFieldTypeDtoV1 } from "./DurationFieldTypeDtoV1";
import type { FlatAnalysisDataDto } from "./FlatAnalysisDataDto";
import type { FormulaFieldTypeDtoV1 } from "./FormulaFieldTypeDtoV1";
import type { LinkFieldTypeDtoV1 } from "./LinkFieldTypeDtoV1";
import type { ListFieldTypeDtoV1 } from "./ListFieldTypeDtoV1";
import type { MultiLineTextFieldTypeDtoV1 } from "./MultiLineTextFieldTypeDtoV1";
import type { NumberFieldTypeDtoV1 } from "./NumberFieldTypeDtoV1";
import type { SingleLineTextFieldTypeDtoV1 } from "./SingleLineTextFieldTypeDtoV1";

export type PendingContractDto = {
  id: string;
  durationType: PendingContractDto.durationType | null;
  paymentType: PendingContractDto.paymentType | null;
  paymentCycle: PendingContractDto.paymentCycle | null;
  fields: Record<
    string,
    | AmountFieldTypeDtoV1
    | CountryFieldTypeDtoV1
    | DateFieldTypeDtoV1
    | LinkFieldTypeDtoV1
    | ListFieldTypeDtoV1
    | MultiLineTextFieldTypeDtoV1
    | NumberFieldTypeDtoV1
    | SingleLineTextFieldTypeDtoV1
    | FormulaFieldTypeDtoV1
    | DurationFieldTypeDtoV1
  >;
  name: string;
  categoryId: string;
  teamName: string;
  organizationName: string;
  durationStartAt: string | null;
  durationEndAt: string | null;
  durationInterval: number | null;
  durationCancellation: number | null;
  durationCancellationAt: string | null;
  durationRenewal: number | null;
  paymentPrice: number | null;
  paymentCurrencyCode: string;
  paymentTax: number | null;
  createdBy: string;
  createdAt: string;
  category: CategoryDTOV1;
  analysisData: AnalysisDataDto | null;
  analysisStatus: string | null;
  analysisCategory: string | null;
  flatAnalysisData: FlatAnalysisDataDto | null;
  categories: Array<ContractCategoryDto>;
  fieldDefinitions: Array<ContractFieldDTOV1>;
  analysisCategoryMappings: Array<AnalysisCategoryMappingDto>;
  analysisFieldMappings: Array<AnalysisFieldMappingDto>;
  text: string | null;
  textractResult: Record<string, any> | null;
  classificationResult: Record<string, any> | null;
  entityRecognitionResult: Record<string, any> | null;
  contactTypes: Array<ContactTypeDTO>;
  contactDefinitions: Array<ContactDatapointDefinitionDTO>;
  autosave: AutosaveDTO | null;
};

export namespace PendingContractDto {
  export enum durationType {
    FIXED_TERM = "FIXED_TERM",
    AUTOMATIC_RENEWAL = "AUTOMATIC_RENEWAL",
    INDEFINITE_DURATION = "INDEFINITE_DURATION",
  }

  export enum paymentType {
    EXPENSE = "EXPENSE",
    INCOME = "INCOME",
    OTHER = "OTHER",
  }

  export enum paymentCycle {
    PER_HOUR = "PER_HOUR",
    PER_DAY = "PER_DAY",
    PROJECT_BASED = "PROJECT_BASED",
    ONETIME = "ONETIME",
    MONTHLY = "MONTHLY",
    BIMONTHLY = "BIMONTHLY",
    QUARTERLY = "QUARTERLY",
    BIANNUAL = "BIANNUAL",
    YEARLY = "YEARLY",
    EVERY_2_YEARS = "EVERY_2_YEARS",
    EVERY_3_YEARS = "EVERY_3_YEARS",
    NO_PAYMENT = "NO_PAYMENT",
  }
}
