import "./theme.css";

const theme = {
  code: "editor-code",
  heading: {
    h1: "editor-heading-h1",
    h2: "editor-heading-h2",
    h3: "editor-heading-h3",
    h4: "editor-heading-h4",
    h5: "editor-heading-h5",
  },
  image: "editor-image",
  indent: "editor-indent",
  link: "editor-link",
  list: {
    listitem: "editor-listitem",
    nested: {
      listitem: "editor-nested-listitem",
    },
    olDepth: [
      "editor-list-ol-depth-0",
      "editor-list-ol-depth-1",
      "editor-list-ol-depth-2",
      "editor-list-ol-depth-3",
      "editor-list-ol-depth-4",
    ],
    ul: "editor-list-ul",
  },
  ltr: "editor-ltr",
  paragraph: "editor-paragraph",
  placeholder: "editor-placeholder",
  placeholderText: "editor-placeholder-text",
  quote: "editor-quote",
  rtl: "editor-rtl",
  text: {
    bold: "editor-text-bold",
    code: "editor-text-code",
    hashtag: "editor-text-hashtag",
    italic: "editor-text-italic",
    overflowed: "editor-text-overflowed",
    strikethrough: "editor-text-strikethrough",
    underline: "editor-text-underline",
    underlineStrikethrough: "editor-text-underlineStrikethrough",
  },
  table: "editor-table",
  tableCell: "editor-tableCell",
  tableCellActionButton: "editor-tableCellActionButton",
  tableCellActionButtonContainer: "editor-tableCellActionButtonContainer",
  tableCellEditing: "editor-tableCellEditing",
  tableCellHeader: "editor-tableCellHeader",
  tableCellPrimarySelected: "editor-tableCellPrimarySelected",
  tableCellResizer: "editor-tableCellResizer",
  tableCellSelected: "editor-tableCellSelected",
  tableCellSortedIndicator: "editor-tableCellSortedIndicator",
  tableResizeRuler: "editor-tableCellResizeRuler",
  tableSelected: "editor-tableSelected",
  tableSelection: "editor-tableSelection",
};
export default theme;
