import { Autocomplete, Tooltip } from "@mui/material";
import React from "react";
import {
  AutoCompleteOption,
  FontInput,
  FontInputWrapper,
  Icon,
  StyledPopper,
} from "./styles";
import { $getSelection, LexicalEditor } from "lexical";
import { $patchStyleText } from "@lexical/selection";
import { useTranslation } from "react-i18next";

const FONT_FAMILY_OPTIONS: [string, string][] = [
  ["Arial", "Arial"],
  ["Courier New", "Courier New"],
  ["Georgia", "Georgia"],
  ["Times New Roman", "Times New Roman"],
  ["Trebuchet MS", "Trebuchet MS"],
  ["Verdana", "Verdana"],
];

type FontSelectorProps = {
  editor: LexicalEditor;
  value: string;
  disabled?: boolean;
};

export const FontSelector = (props: FontSelectorProps) => {
  const options = FONT_FAMILY_OPTIONS.map(([value, label]) => ({
    value,
    label,
  }));

  const { t } = useTranslation();

  const activeOption = options.find(
    (option) => option.value === props.value
  ) ?? {
    label: "",
    value: "",
  };

  const handleClick = (option: string) => {
    props.editor.update(() => {
      const selection = $getSelection();
      if (selection !== null) {
        $patchStyleText(selection, { "font-family": option });
      }
    });
  };

  return (
    <Autocomplete
      options={options}
      disableClearable
      disabled={props.disabled}
      onChange={(e, option) => {
        handleClick(option.value);
      }}
      value={activeOption}
      PopperComponent={StyledPopper}
      renderInput={(params) => (
        <Tooltip
          title={t("textEditor.plugins.toolbar.fontFamily")}
          disableHoverListener={props.disabled}
          disableFocusListener={props.disabled}
          disableTouchListener={props.disabled}
        >
          <FontInputWrapper ref={params.InputProps.ref}>
            <FontInput {...params.inputProps} fontFamily={activeOption.value} />
            <Icon />
          </FontInputWrapper>
        </Tooltip>
      )}
      renderOption={(props, option) => (
        <AutoCompleteOption {...props}>{option.label}</AutoCompleteOption>
      )}
    />
  );
};
