import styled from "@emotion/styled";
import theme from "theme/theme";

export const EditorContainer = styled.div`
  background: #fff;
  position: relative;
  display: block;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

export const Editor = styled.div`
  flex: auto;
  position: relative;
  resize: vertical;
  z-index: -1;
`;

export const EditorScroller = styled.div`
  min-height: 150px;
  border: 0;
  display: flex;
  position: relative;
  outline: 0;
  z-index: 0;
  overflow: auto;
  resize: vertical;
`;

/*
export const ContentEditable = styled(LexicalContentEditable)`
  resize: none;
  font-size: 15px;
  caret-color: ${theme.color.black};
  tab-size: 1;
  outline: 0;
  padding: 15px 10px;
`;
*/

export const PlaceholderElement = styled.div`
  color: ${theme.color.gray[800]};
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  bottom: 3.25em;
  left: 0.75em;
  font-size: 15px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
`;

export const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid ${theme.color.gray[400]};
  padding: 0.5em 0;
  overflow: auto;
  min-height: 57px;
  width: 100%;
  background-color: ${theme.color.white};
`;

export const ContractTemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 0 3.5em 0;
`;
