import React from "react";
import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";
import { IconButton } from "@mui/material";
import { ContractTemplateTagDTO, CategoryDTO } from "openapi";
import { CTAButton } from "components";
import { Title, Import, CategorySelector, Tags } from "./components";
import {
  Wrapper,
  Container,
  HeadersIcons,
  TemplateIcon,
  ArrowIcon,
  Group,
} from "./styles";

type HeaderProps = {
  control: Control;
  handleOnGoBack: () => void;
  handleOnImport: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnSave: () => void;
  handleOnDelete: () => void;
  handleTemplateDuplication: () => void;
  hasDirtyFields?: boolean;
  lasModified?: string;
  categoryId?: string;
  handleCategoryChange: (value: string) => void;
  categories?: CategoryDTO[];
  currentCategory?: CategoryDTO;
  handleTagsChange?: (tags: { key: string; value: string }[]) => void;
  preselectedTags: ContractTemplateTagDTO[];
};

const Header = ({
  handleOnGoBack,
  handleOnImport,
  handleOnSave,
  handleOnDelete,
  handleTemplateDuplication,
  hasDirtyFields,
  control,
  lasModified,
  handleCategoryChange,
  handleTagsChange,
  categories,
  currentCategory,
  preselectedTags,
}: HeaderProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Container>
        <HeadersIcons>
          <TemplateIcon />
          <IconButton onClick={handleOnGoBack}>
            <ArrowIcon />
          </IconButton>
        </HeadersIcons>

        <Group>
          <Title control={control} name="name" lasModified={lasModified} />
          <CategorySelector
            handleCategoryChange={handleCategoryChange}
            categories={categories}
            currentCategory={currentCategory}
          />
          <Tags
            isEditable
            maxTagsCount={3}
            preselectedTags={preselectedTags}
            handleOnChange={handleTagsChange}
          />
        </Group>
      </Container>

      <Container>
        <Import
          handleOnImport={handleOnImport}
          handleDeleteContractTemplate={handleOnDelete}
          handleTemplateDuplication={handleTemplateDuplication}
        />

        <CTAButton
          type="submit"
          name={t("common.buttons.save")}
          onClick={handleOnSave}
          disabled={!hasDirtyFields}
        />
      </Container>
    </Wrapper>
  );
};

export default Header;
