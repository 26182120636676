/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import type Mammoth from "mammoth";
import mammoth from "mammoth/mammoth.browser";

export const convertDocxToHTML = async (docx: ArrayBuffer): Promise<string> => {
  const result = await (mammoth as typeof Mammoth).convertToHtml(
    { arrayBuffer: docx },
    {
      styleMap: ["br[type='page'] => hr[type='page-break']"],
    }
  );

  return result.value;
};
