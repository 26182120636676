import { ColDef, ColumnState } from "ag-grid-community";
import _ from "lodash";
import { ContractDTOV1, ContractViewItemDto } from "openapi";
import dayjs from "dayjs";

export const adjustColumnDefs = (
  colDefs: ColDef[],
  columnValues: Record<string, number>
) => {
  return colDefs.sort((a, b) => {
    const posA = columnValues[a.colId ?? a.field ?? ""] || 0;
    const posB = columnValues[b.colId ?? b.field ?? ""] || 0;
    return posA - posB;
  });
};

export const getTaskDueDateTemplate = (
  initialColumnState: ColumnState[]
): ContractViewTemplate => {
  const columnState = _.cloneDeep(initialColumnState);
  const col = columnState.find((c) => c.colId === "dueDate");
  if (col) {
    col.sort = "asc";
  }

  return {
    type: ContractViewItemDto.type.CONTRACT,
    id: "task_due_date_view",
    name: "reminderFilterView",
    data: JSON.stringify(columnState),
    filter: JSON.stringify({
      dueDate: {
        dateFrom: dayjs().startOf("day").toDate(),
        dateTo: dayjs().add(30, "day").endOf("day").toDate(),
        filterType: "date",
        type: "inRange",
      },
    }),
    persist: true,
    shared: false,
    owner: {},
  };
};

export const getContractsWithoutRemindersViewTemplate = (
  initialColumnState: ColumnState[]
) => {
  const columnState = _.cloneDeep(initialColumnState);
  const relevantColumns = [
    "ag-grid-AutoColumn",
    "reminderAt",
    "durationStartAt",
    "durationEndAt",
  ];
  for (const col of columnState) {
    col.hide = !relevantColumns.includes(col.colId);
  }

  return {
    type: ContractViewItemDto.type.CONTRACT,
    id: "contracts_without_reminders_view",
    name: "contractsWithoutRemindersView",
    data: JSON.stringify(columnState),
    filter: JSON.stringify({
      reminderAt: {
        dateFrom: null,
        dateTo: null,
        filterType: "date",
        type: "blank",
      },
    }),
    persist: true,
    shared: false,
    owner: {},
  };
};

export const getContractsSortedByRecentView = (
  initialColumnState: ColumnState[]
) => {
  const columnState = _.cloneDeep(initialColumnState);

  const createdAtIdx = columnState.findIndex((c) => c.colId === "createdAt");
  const createdAtColumn = columnState.splice(createdAtIdx, 1)[0];
  createdAtColumn.hide = false;
  createdAtColumn.sort = "desc";

  columnState.splice(1, 0, createdAtColumn);

  return {
    type: ContractViewItemDto.type.CONTRACT,
    id: "recently_added_contracts_view",
    name: "recentlyAddedContractsView",
    data: JSON.stringify(columnState),
    filter: "",
    persist: true,
    shared: false,
    owner: {},
  };
};

export const getContactContractsView = (initialColumnState: ColumnState[]) => {
  const columnState = _.cloneDeep(initialColumnState);
  const relevantColumns = [
    "ag-grid-AutoColumn",
    "tag",
    "categoryId",
    "durationEndAt",
    "paymentType",
  ];
  for (const col of columnState) {
    col.hide = !relevantColumns.includes(col.colId);
  }

  return {
    type: ContractViewItemDto.type.CONTRACT,
    id: "contact_contracts_view",
    name: "contactContractsView",
    data: JSON.stringify(columnState),
    filter: "",
    persist: false,
    shared: false,
    owner: {},
  };
};

export const getPendingSignaturesViewTemplate = (
  initialColumnState: ColumnState[]
) => {
  const columnState = _.cloneDeep(initialColumnState);

  const relevantColumns = [
    "ag-grid-AutoColumn",
    "tag",
    "categoryId",
    "durationStartAt",
    "durationEndAt",
    "counterParty",
    "status",
  ];

  for (const col of columnState) {
    col.hide = !relevantColumns.includes(col.colId);
  }

  return {
    type: ContractViewItemDto.type.CONTRACT,
    id: "pending_signatures_view",
    name: "pendingSignaturesView",
    data: JSON.stringify(columnState),
    filter: JSON.stringify({
      status: {
        values: [ContractDTOV1.status.SIGNING],
        filterType: "set",
      },
    }),
    persist: false,
    shared: false,
    owner: {},
  };
};

export const getContractAnalysisViewTemplate = (
  initialColumnState: ColumnState[]
) => {
  const columnState = _.cloneDeep(initialColumnState);
  const relevantColumns = [
    "ag-grid-AutoColumn",
    "categoryId",
    "durationStartAt",
    "durationEndAt",
    "partnerCompany",
    "status",
    "unapprovedAnalysisValues",
    "verifiedFieldsCount",
    "paymentPriceNet",
    "paymentCycle",
  ];

  for (const col of columnState) {
    col.hide = !relevantColumns.includes(col.colId);
  }

  return {
    type: ContractViewItemDto.type.CONTRACT,
    id: "contract_analysis_view",
    name: "contractAnalysisView",
    data: JSON.stringify(columnState),
    filter: JSON.stringify({
      unapprovedAnalysisValues: {
        values: ["true"],
        filterType: "set",
      },
    }),
    persist: false,
    shared: false,
    owner: {},
  };
};

export type ContractViewTemplate = ContractViewItemDto & {
  persist: boolean;
  shared: boolean;
  owner: object | undefined;
};

export const availableTemplates: Record<
  | "task_due_date_view"
  | "contracts_without_reminders_view"
  | "recently_added_contracts_view"
  | "contact_contracts_view"
  | "pending_signatures_view"
  | "contract_analysis_view",
  (initialColumnState: ColumnState[]) => ContractViewTemplate
> = {
  task_due_date_view: getTaskDueDateTemplate,
  contracts_without_reminders_view: getContractsWithoutRemindersViewTemplate,
  recently_added_contracts_view: getContractsSortedByRecentView,
  contact_contracts_view: getContactContractsView,
  pending_signatures_view: getPendingSignaturesViewTemplate,
  contract_analysis_view: getContractAnalysisViewTemplate,
} as const;

export type AvailableTemplates = keyof typeof availableTemplates;
