import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  MutableRefObject,
} from "react";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import { Popover, Typography, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CloseIcon } from "components/Chip/styles";
import { TextField } from "new-components";
import {
  useCreateContractTemplateTagMutation,
  useGetAllContractTemplateTagsQuery,
  useUpdateContractTemplateTagMutation,
  useContractTemplateUpdateMutationV1,
} from "shared/api/contract-templates";
import { useTeam } from "contexts/team/hooks";
import { ContractTemplateTagDTO, ContractTemplateV1DTO } from "openapi";
import DeleteTagModal from "./DeleteModal";
import {
  TagWrapper,
  AddIcon,
  Tag,
  TagIcon,
  AddTagButton,
  SelectedTags,
  TagsContainer,
  TagCount,
  TagSettings,
  Rename,
  EditIcon,
  BinIcon,
  List,
  ListItem,
} from "./styles";

type TagInputProps = {
  anchorEl: HTMLButtonElement | null;
  templateRef?: MutableRefObject<ContractTemplateV1DTO>;
  handleUpdateTemplateTags: (tags: { key: string; value: string }[]) => void;
  handleOpenPopover: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleClosePopover: () => void;
  allTags: Array<{ key: string; value: string }>;
  selectedTags: Array<{ key: string; value: string }>;
  setSelectedTags: (value: Array<{ key: string; value: string }>) => void;
  onAddTag: (tagValue: string) => void;
  onRemoveTag: (tagKey: string, closeAll?: () => void) => void;
  onRenameTag: (tagKey: string, newName: string) => void;
  refetch: () => void;
  showAddButton?: boolean;
};
const TagInput = ({
  anchorEl,
  templateRef,
  handleOpenPopover,
  handleClosePopover,
  handleUpdateTemplateTags,
  allTags,
  selectedTags,
  setSelectedTags,
  onAddTag,
  onRemoveTag,
  onRenameTag,
  refetch,
  showAddButton,
}: TagInputProps) => {
  const { t } = useTranslation();
  const [tagOptionsAnchorEl, setTagOptionsAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [currentTag, setCurrentTag] = useState<{
    key: string;
    value: string;
  } | null>(null);
  const [isRenaming, setIsRenaming] = useState(false);
  const [newTagName, setNewTagName] = useState("");
  const [showDeleteTagModal, setShowDeleteTagModal] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (anchorEl) inputRef.current?.focus();
  }, [anchorEl]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleAddTag = () => {
    if (inputValue.trim()) {
      const isAlreadySelected = selectedTags.some(
        (tag) => tag.value.toLowerCase() === inputValue.trim().toLowerCase()
      );

      if (!isAlreadySelected) {
        onAddTag(inputValue.trim());
        setInputValue("");
      } else {
        enqueueSnackbar(
          t(
            "pages.settings.tabs.contractTemplates.tags.snackbars.alreadySelected"
          ),
          { variant: "error" }
        );
      }
    }
  };

  const handleOptionSelect = (tag: { key: string; value: string }) => {
    const isAlreadySelected = selectedTags.some(
      (selectedTag) => selectedTag.key === tag.key
    );
    if (!isAlreadySelected) {
      onAddTag(tag.value);
      templateRef?.current.id &&
        void handleUpdateTemplateTags([...selectedTags, tag]);
      setInputValue("");
    } else {
      enqueueSnackbar(
        t(
          "pages.settings.tabs.contractTemplates.tags.snackbars.alreadySelected"
        ),
        { variant: "error" }
      );
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") handleAddTag();
  };

  const handleTagOptionsOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    tag: { key: string; value: string }
  ) => {
    event.stopPropagation();
    setTagOptionsAnchorEl(event.currentTarget);
    setCurrentTag(tag);
  };

  const handleTagOptionsClose = () => {
    setTagOptionsAnchorEl(null);
    setCurrentTag(null);
    setIsRenaming(false);
    setNewTagName("");
  };

  const handleRenameClick = () => {
    setIsRenaming(true);
    setNewTagName(currentTag?.value || "");
  };

  const handleRenameConfirm = () => {
    if (currentTag && newTagName.trim() && newTagName !== currentTag.value) {
      onRenameTag(currentTag.key, newTagName.trim());
    }
    handleTagOptionsClose();
  };

  const handleDeleteClick = () => setShowDeleteTagModal(true);

  const filteredTags = allTags.filter(
    (tag) =>
      !selectedTags.some((selectedTag) => selectedTag.key === tag.key) &&
      tag.value.toLowerCase().includes(inputValue.toLowerCase())
  );

  return (
    <>
      {showAddButton && (
        <AddTagButton type="button" onClick={handleOpenPopover} visible={true}>
          <AddIcon />
          {t("pages.settings.organization.contractTemplates.buttons.addTag")}
        </AddTagButton>
      )}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            padding: "8px",
            marginTop: ".5rem",
            maxWidth: "420px",
            maxHeight: "520px",
            overflow: "hidden",
          },
        }}
      >
        <div>
          <TextField
            name="rename"
            fullWidth
            value={inputValue}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder={t(
              "pages.settings.tabs.contractTemplates.tags.search.placeholder"
            )}
            ref={inputRef}
            size="sm"
          />
          {selectedTags.length > 0 && (
            <Typography variant="subtitle2" mt={2} mb={1}>
              {t("pages.settings.tabs.contractTemplates.tags.selected")}
            </Typography>
          )}
          <SelectedTags>
            {selectedTags.map((tag: { key: string; value: string }) => (
              <Tag key={tag.key}>
                <TagIcon />
                {tag.value}
                <CloseIcon
                  className="close-icon"
                  onClick={() => {
                    const closeAll = () => {
                      handleClosePopover();
                      handleTagOptionsClose();
                    };
                    // @Note: Because the count will disappear there will be no anchor for the popover
                    // so we close the popover here.
                    if (selectedTags.length === 3) {
                      onRemoveTag(tag.key, closeAll);
                      return;
                    }
                    onRemoveTag(tag.key);
                  }}
                />
              </Tag>
            ))}
          </SelectedTags>
          <Typography variant="subtitle2" mt={2} mb={1}>
            {t(
              "pages.settings.tabs.contractTemplates.tags.selectionSuggestion"
            )}
          </Typography>

          <List style={{ maxHeight: "320px", overflowY: "scroll" }}>
            {filteredTags.map((tag) => (
              <ListItem key={tag.key} onClick={() => handleOptionSelect(tag)}>
                <Tag>
                  <TagIcon />
                  {tag.value}
                </Tag>
                <IconButton
                  size="small"
                  onClick={(event) => handleTagOptionsOpen(event, tag)}
                >
                  <MoreVertIcon fontSize="small" sx={{ rotate: "90deg" }} />
                </IconButton>
              </ListItem>
            ))}
          </List>

          {filteredTags.length === 0 && inputValue && (
            <ListItem onClick={() => handleAddTag()}>
              <Tag>
                <TagIcon />
                {t("pages.settings.tabs.contractTemplates.tags.create", {
                  name: inputValue,
                })}
              </Tag>
            </ListItem>
          )}
          {filteredTags.length === 0 &&
            !inputValue &&
            t("pages.settings.tabs.contractTemplates.tags.empty")}
        </div>
      </Popover>
      <Popover
        open={Boolean(tagOptionsAnchorEl)}
        anchorEl={tagOptionsAnchorEl}
        onClose={handleTagOptionsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            padding: "8px",
          },
        }}
      >
        <TagSettings>
          {isRenaming ? (
            <List>
              <ListItem>
                <TextField
                  name="rename"
                  value={newTagName}
                  onChange={(e) => setNewTagName(e.target.value)}
                  onKeyPress={(e) => e.key === "Enter" && handleRenameConfirm()}
                  size="sm"
                />
              </ListItem>
              <ListItem onClick={handleDeleteClick} remove>
                <span>
                  <BinIcon />
                  {t("common.buttons.delete")}
                </span>
              </ListItem>
            </List>
          ) : (
            <List>
              <ListItem onClick={handleRenameClick}>
                <span>
                  <EditIcon />
                  <Rename>{t("common.buttons.rename")}</Rename>
                </span>
              </ListItem>
              <ListItem onClick={handleDeleteClick} remove>
                <span>
                  <BinIcon />
                  {t("common.buttons.delete")}
                </span>
              </ListItem>
            </List>
          )}
        </TagSettings>
      </Popover>

      {currentTag && (
        <DeleteTagModal
          open={showDeleteTagModal}
          handleClose={() => {
            handleTagOptionsClose();
            setShowDeleteTagModal(false);
          }}
          tag={currentTag}
          refetch={refetch}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
      )}
    </>
  );
};

const TagsComponent = ({
  isEditable,
  templateRef,
  maxTagsCount = 2,
  preselectedTags = [],
  handleOnChange,
}: {
  isEditable?: boolean;
  templateRef?: MutableRefObject<ContractTemplateV1DTO>;
  showAddButton?: boolean;
  maxTagsCount?: number;
  preselectedTags: ContractTemplateTagDTO[];
  handleOnChange?: (tags: { key: string; value: string }[]) => void;
}) => {
  const { t } = useTranslation();
  const { organizationId } = useTeam();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showAddButton, setShowAddButton] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const initialTags =
    preselectedTags?.map((tag) => ({ key: tag.id, value: tag.name })) ?? [];
  const [selectedTags, setSelectedTags] = useState(initialTags);

  const { data: tagsData = [], refetch } =
    useGetAllContractTemplateTagsQuery(organizationId);
  const createContractTemplateTagMutation =
    useCreateContractTemplateTagMutation();
  const updateContractTemplateTagMutation =
    useUpdateContractTemplateTagMutation();
  const updateContractTemplate = useContractTemplateUpdateMutationV1();

  const tags = tagsData.map((tag) => ({ key: tag.id, value: tag.name }));

  const updateContractTemplateTags = useCallback(
    async (newTags: { key: string; value: string }[]) => {
      try {
        if (!templateRef?.current.id) return;

        await updateContractTemplate.mutateAsync({
          organizationId,
          templateId: templateRef?.current?.id ?? "",
          template: {
            ...templateRef?.current,
            tagIds: newTags.map((tag) => tag.key),
          },
        });
      } catch (error) {
        enqueueSnackbar(
          t("pages.settings.tabs.contractTemplates.snackbars.updateFailure"),
          {
            variant: "error",
          }
        );
      }
    },
    [organizationId]
  );

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => setAnchorEl(null);

  const handleAddTag = async (newTagValue: string) => {
    const existingTag = tags.find(
      (tag) => tag.value.toLowerCase() === newTagValue.toLowerCase()
    );

    if (existingTag) {
      if (!selectedTags.some((tag) => tag.key === existingTag.key)) {
        setSelectedTags([...selectedTags, existingTag]);
      }
    } else {
      try {
        const newTag = await createContractTemplateTagMutation.mutateAsync({
          organizationId,
          tag: { name: newTagValue },
        });

        const updatedTags = [
          ...selectedTags,
          { key: newTag.id, value: newTag.name },
        ];

        setSelectedTags(updatedTags);

        if (templateRef?.current.id) {
          void updateContractTemplateTags(updatedTags);
        }

        void refetch();
      } catch (error) {
        enqueueSnackbar(
          t(
            "pages.settings.tabs.contractTemplates.tags.snackbars.createFailure"
          ),
          { variant: "error" }
        );
      }
    }
  };

  const handleRemoveTag = (tagKey: string, handlePopoverClose?: () => void) => {
    const updatedTags = selectedTags.filter((tag) => tag.key !== tagKey);
    setSelectedTags(updatedTags);

    if (templateRef?.current.id) {
      void updateContractTemplateTags(
        selectedTags.filter((tag) => tag.key !== tagKey)
      );
    }

    handlePopoverClose?.();
  };

  const handleRenameTag = async (tagKey: string, newName: string) => {
    try {
      const updatedTag = await updateContractTemplateTagMutation.mutateAsync({
        organizationId,
        tagId: tagKey,
        name: newName,
      });
      setSelectedTags(
        selectedTags.map((tag) =>
          tag.key === tagKey ? { ...tag, value: updatedTag.name } : tag
        )
      );
      void refetch();
    } catch (error) {
      enqueueSnackbar(
        t("pages.settings.tabs.contractTemplates.tags.snackbars.renameFailure"),
        { variant: "error" }
      );
    }
  };

  useEffect(() => handleOnChange?.(selectedTags), [selectedTags]);

  useEffect(() => {
    if (!templateRef) return;
    setShowAddButton(templateRef?.current.tags.length === 0);
  }, [templateRef?.current?.tags?.length]);

  const maxTagsCountBasedOnMode = isEditable
    ? maxTagsCount
    : selectedTags.length;

  return (
    <TagWrapper>
      {isEditable && (
        <TagInput
          templateRef={templateRef}
          handleUpdateTemplateTags={updateContractTemplateTags}
          showAddButton={showAddButton}
          allTags={tags}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          onAddTag={handleAddTag}
          onRemoveTag={handleRemoveTag}
          onRenameTag={handleRenameTag}
          handleClosePopover={handleClosePopover}
          handleOpenPopover={handleOpenPopover}
          anchorEl={anchorEl}
          refetch={refetch}
        />
      )}
      <TagsContainer
        type="button"
        onClick={handleOpenPopover}
        stretched={!templateRef?.current.id}
      >
        {selectedTags
          .slice(0, maxTagsCountBasedOnMode)
          .map((tag: { key: string; value: string }) => (
            <Tag key={tag.key}>
              <TagIcon />
              {tag.value}
            </Tag>
          ))}
        {selectedTags.length > maxTagsCountBasedOnMode && (
          <TagCount>
            <TagIcon />+{selectedTags.length - maxTagsCount}
          </TagCount>
        )}
      </TagsContainer>
    </TagWrapper>
  );
};

export default TagsComponent;
