import { IconButton } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { StyledTextField } from "components/StyledComponents/StyledBaseFields";
import { useOverviewState } from "contexts/grid/hooks";
import { ContractViewItemDto } from "openapi";
import {
  persistTemporarySearch,
  retrieveTemporarySearch,
} from "contexts/grid/storage";
import React, { FC, useEffect, useState } from "react";
import { useLocation } from "react-router";

export type EntityGridSearchInputProps = {
  label: string;
};

const pathMapping = {
  [ContractViewItemDto.type.CONTRACT]: "contracts",
  [ContractViewItemDto.type.TASK]: "tasks",
  [ContractViewItemDto.type.CONTACT]: "contacts",
};

export const EntityGridSearchInput: FC<EntityGridSearchInputProps> = ({
  label,
}) => {
  const { overview } = useOverviewState();
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const isNestedOverview = path !== pathMapping[overview.type];

  const [search, setSearch] = useState(
    overview.activePreset || isNestedOverview
      ? ""
      : retrieveTemporarySearch(overview.type)
  );

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (overview.agGrid.initialized) {
        overview.agGrid.gridRef.current?.api.setQuickFilter(search);
        if (!overview.activePreset && !isNestedOverview) {
          persistTemporarySearch(overview.type, search);
        }
      }
    }, 350);

    return () => clearTimeout(debounceTimeout);
  }, [search]);

  return (
    <StyledTextField
      margin="none"
      size="small"
      name="search"
      label={label}
      value={search}
      onChange={(e) => {
        setSearch(e.currentTarget.value);
      }}
      sx={{
        flex: 1,
        minWidth: "200px",
        maxWidth: "300px",
      }}
      InputProps={
        search !== ""
          ? {
              endAdornment: (
                <IconButton onClick={() => setSearch("")} aria-label="clear">
                  <ClearIcon />
                </IconButton>
              ),
            }
          : undefined
      }
    />
  );
};
